export const itemFilter = {
  fragments: [
    {
      name: "Harvest Lifeforce",
      items: [
        "Wild Crystallised Lifeforce",
        "Sacred Crystallised Lifeforce",
        "Primal Crystallised Lifeforce",
        "Vivid Crystallised Lifeforce",
      ],
      icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvSGFydmVzdC9QcmltYWxMaWZlZm9yY2UiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/d5d53c61c3/PrimalLifeforce.png",
    },
    {
      name: "Gilded Scarabs",
      items: [
        "Gilded Divination Scarab",
        "Gilded Reliquary Scarab",
        "Gilded Harbinger Scarab",
        "Gilded Ambush Scarab",
        "Gilded Breach Scarab",
        "Gilded Abyss Scarab",
        "Gilded Expedition Scarab",
        "Gilded Legion Scarab",
        "Gilded Bestiary Scarab",
        "Gilded Blight Scarab",
        "Gilded Cartography Scarab",
        "Gilded Sulphite Scarab",
        "Gilded Metamorph Scarab",
        "Gilded Torment Scarab",
        "Gilded Shaper Scarab",
        "Gilded Elder Scarab",
      ],
      icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiRGl2aW5hdGlvbiIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/8101d1a6e1/GreaterScarabDivination.png",
    },
    {
      name: "Winged Scarabs",
      items: [
        "Winged Divination Scarab",
        "Winged Reliquary Scarab",
        "Winged Harbinger Scarab",
        "Winged Ambush Scarab",
        "Winged Breach Scarab",
        "Winged Abyss Scarab",
        "Winged Expedition Scarab",
        "Winged Legion Scarab",
        "Winged Bestiary Scarab",
        "Winged Blight Scarab",
        "Winged Cartography Scarab",
        "Winged Sulphite Scarab",
        "Winged Metamorph Scarab",
        "Winged Torment Scarab",
        "Winged Shaper Scarab",
        "Winged Elder Scarab",
      ],
      icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYkRpdmluYXRpb24iLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/e6539f578d/Tier4ScarabDivination.png",
    },
    {
      name: "Shrieking Essences",
      items: [
        "Shrieking Essence of Contempt",
        "Shrieking Essence of Greed",
        "Shrieking Essence of Wrath",
        "Shrieking Essence of Zeal",
        "Shrieking Essence of Dread",
        "Shrieking Essence of Envy",
        "Shrieking Essence of Hatred",
        "Shrieking Essence of Loathing",
        "Shrieking Essence of Rage",
        "Shrieking Essence of Scorn",
        "Shrieking Essence of Sorrow",
        "Shrieking Essence of Spite",
        "Shrieking Essence of Anger",
        "Shrieking Essence of Fear",
        "Shrieking Essence of Woe",
        "Shrieking Essence of Anguish",
        "Shrieking Essence of Doubt",
        "Shrieking Essence of Misery",
        "Shrieking Essence of Suffering",
        "Shrieking Essence of Torment",
      ],
      icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvRXNzZW5jZS9Db250ZW1wdDYiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/332e9b32e9/Contempt6.png",
    },
    {
      name: "Deafening Essences",
      items: [
        "Deafening Essence of Contempt",
        "Deafening Essence of Greed",
        "Deafening Essence of Wrath",
        "Deafening Essence of Zeal",
        "Deafening Essence of Dread",
        "Deafening Essence of Envy",
        "Deafening Essence of Hatred",
        "Deafening Essence of Loathing",
        "Deafening Essence of Rage",
        "Deafening Essence of Scorn",
        "Deafening Essence of Sorrow",
        "Deafening Essence of Spite",
        "Deafening Essence of Anger",
        "Deafening Essence of Fear",
        "Deafening Essence of Woe",
        "Deafening Essence of Anguish",
        "Deafening Essence of Doubt",
        "Deafening Essence of Misery",
        "Deafening Essence of Suffering",
        "Deafening Essence of Torment",
      ],
      icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvRXNzZW5jZS9Db250ZW1wdDciLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/eabee1b2e2/Contempt7.png",
    },
    {
      name: "Breachstones",
      items: [
        "Uul-Netol's Flawless Breachstone",
        "Xoph's Flawless Breachstone",
        "Tul's Flawless Breachstone",
        "Esh's Flawless Breachstone",
        "Chayula's Pure Breachstone",
        "Chayula's Breachstone",
        "Chayula's Charged Breachstone",
        "Chayula's Enriched Breachstone",
        "Uul-Netol's Pure Breachstone",
        "Uul-Netol's Enriched Breachstone",
        "Esh's Pure Breachstone",
        "Tul's Pure Breachstone",
        "Uul-Netol's Charged Breachstone",
        "Xoph's Pure Breachstone",
        "Uul-Netol's Breachstone",
        "Xoph's Enriched Breachstone",
        "Tul's Enriched Breachstone",
        "Esh's Enriched Breachstone",
        "Xoph's Charged Breachstone",
        "Tul's Charged Breachstone",
        "Esh's Charged Breachstone",
        "Esh's Breachstone",
        "Xoph's Breachstone",
        "Tul's Breachstone",
        "Chayula's Flawless Breachstone",
      ],
      icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvQnJlYWNoL0JyZWFjaEZyYWdtZW50c0NoYW9zIiwic2NhbGUiOjF9XQ/4c99b7dd49/BreachFragmentsChaos.png",
    },
    {
      name: "Emblems",
      items: [
        "Timeless Maraketh Emblem",
        "Timeless Templar Emblem",
        "Unrelenting Timeless Karui Emblem",
        "Timeless Vaal Emblem",
        "Timeless Karui Emblem",
        "Unrelenting Timeless Maraketh Emblem",
        "Unrelenting Timeless Eternal Emblem",
        "Unrelenting Timeless Templar Emblem",
        "Unrelenting Timeless Vaal Emblem",
        "Timeless Eternal Emblem",
      ],
      icon: "https://web.poecdn.com/gen/image/WzI4LDE0LHsiZiI6IjJESXRlbXMvTWFwcy9NYXJha2V0aEZyYWdtZW50Iiwic2NhbGUiOjF9XQ/a81c4968ab/MarakethFragment.png",
    },
    {
      name: "Atziri",
      items: [
        "Sacrifice at Midnight",
        "Sacrifice at Noon",
        "Sacrifice at Dawn",
        "Sacrifice at Dusk",
      ],
      icon: "https://web.poecdn.com/gen/image/WzI4LDE0LHsiZiI6IjJESXRlbXMvTWFwcy9WYWFsQ29tcGxldGUiLCJzY2FsZSI6MX1d/63035d86d7/VaalComplete.png",
    },
    {
      name: "Uber Atziri",
      items: ["Mortal Rage", "Mortal Ignorance", "Mortal Hope", "Mortal Grief"],
      icon: "https://web.poecdn.com/gen/image/WzI4LDE0LHsiZiI6IjJESXRlbXMvTWFwcy9VYmVyVmFhbENvbXBsZXRlIiwic2NhbGUiOjF9XQ/994d9e2821/UberVaalComplete.png",
    },
    {
      name: "Elder",
      items: [
        "Fragment of Enslavement",
        "Fragment of Eradication",
        "Fragment of Constriction",
        "Fragment of Purification",
        "Fragment of Terror",
      ],
      icon: "https://web.poecdn.com/gen/image/WzI4LDE0LHsiZiI6IjJESXRlbXMvTWFwcy9FbGRlckNvbXBsZXRlIiwic2NhbGUiOjF9XQ/6db44597fe/ElderComplete.png",
    },
    {
      name: "Shaper",
      items: [
        "Fragment of the Hydra",
        "Fragment of the Phoenix",
        "Fragment of the Minotaur",
        "Fragment of the Chimera",
      ],
      icon: "https://web.poecdn.com/gen/image/WzI4LDE0LHsiZiI6IjJESXRlbXMvTWFwcy9TaGFwZXJDb21wbGV0ZSIsInNjYWxlIjoxfV0/ace686004d/ShaperComplete.png",
    },
    {
      name: "Uber Elder",
      items: [
        "Fragment of Knowledge",
        "Fragment of Shape",
        "Fragment of Terror",
        "Fragment of Emptiness",
      ],
      icon: "https://web.poecdn.com/gen/image/WzI4LDE0LHsiZiI6IjJESXRlbXMvTWFwcy9VYmVyRWxkZXJDb21wbGV0ZSIsInNjYWxlIjoxfV0/715e041869/UberElderComplete.png",
    },
    {
      name: "Crest of the Elderslayers",
      items: [
        "Drox's Crest",
        "Veritania's Crest",
        "Baran's Crest",
        "Al-Hezmin's Crest",
      ],
      icon: "https://web.poecdn.com/gen/image/WzI4LDE0LHsiZiI6IjJESXRlbXMvTWFwcy9TaXJ1c0ZyYWdtZW50Q29tcGxldGUiLCJzY2FsZSI6MX1d/c585a0ae79/SirusFragmentComplete.png",
    },
  ],
  sextantFilter: {
    items: ["Sextant"],
    name: "Compass",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvQ2hhcmdlZENvbXBhc3MiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/ea8fcc3e35/ChargedCompass.png",
  },
  contractFilter: {
    items: [
      "Contract Agility 81+",
      "Contract Brute Force 81+",
      "Contract Counter-Thaumaturgy 81+",
      "Contract Deception 81+",
      "Contract Demolition 81+",
      "Contract Engineering 81+",
      "Contract Lockpicking 81+",
      "Contract Perception 81+",
      "Contract Trap Disarmament 81+",

      "Contract Agility",
      "Contract Brute Force",
      "Contract Counter-Thaumaturgy",
      "Contract Deception",
      "Contract Demolition",
      "Contract Engineering",
      "Contract Lockpicking",
      "Contract Perception",
      "Contract Trap Disarmament",
    ],
    name: "Contract ",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvSGVpc3QvQ29udHJhY3RJdGVtMiIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/f755c71433/ContractItem2.png",
  },

  coffinsFilter: {
    items: [
      "20% chance to craft an additional Item",
      "Creates an Experimented Weapon",
      "Creates an Experimented Weapon",
      "20% chance to craft an additional Item",
      "20% chance to craft an additional Item",
      "Gem Modifiers are 100% scarcer",
      "Creates a Grasping Mail",
      "Creates a Sacrificial Garb",
      "Creates a Grasping Mail",
      "Gem Modifiers are 300% scarcer",
      "+50 to Elemental Modifier Tier Rating",
      "Attribute Modifiers are 100% scarcer",
      "+50 to Critical Modifier Tier Rating",
      "Creates a Talisman",
      "Gem Modifiers are 300% scarcer",
      "Gem Modifiers are 100% scarcer",
      "Resistance Modifiers are 300% scarcer",
      "Gem Modifiers are 300% scarcer",
      "Creates a Talisman",
      "+100 to Speed Modifier Tier Rating",
      "25% chance to create a Mirrored Copy",
      "Gem Modifiers are 300% scarcer",
      "Reforge socket numbers 30 times, keeping the greatest number of sockets",
      "Belt is a Stygian Vise",
      "Gem Modifiers are 100% scarcer",
      "Gem Modifiers are 100% scarcer",
      "Belt is a Stygian Vise",
      "+50 to Attack Modifier Tier Rating",
      "25% chance to create a Mirrored Copy",
      "Resistance Modifiers are 300% scarcer",
      "+50 to Chaos Modifier Tier Rating",
      "+100 to Mana Modifier Tier Rating",
      "Creates a Helmet, Gloves or Boots with Ward",
      "+1 to minimum number of Linked Sockets, up to a maximum of 6",
      "+50 to Gem Modifier Tier Rating",
      "Creates a Helmet, Gloves or Boots with Ward",
      "200% increased chance of Critical Modifiers",
      "+25 to Attack Modifier Tier Rating",
      "500% increased chance of Critical Modifiers",
      "Haunted by Kostian Perandus",
      "200% increased chance of Speed Modifiers",
      "Critical Modifiers are 100% scarcer",
      "200% increased chance of Fire Modifiers",
      "200% increased chance of Gem Modifiers",
      "25% chance to create a Mirrored Copy",
      "Resistance Modifiers are 100% scarcer",
      "Resistance Modifiers are 300% scarcer",
      "500% increased chance of Speed Modifiers",
      "Creates a Ritual Helmet, Gloves or Boots",
      "Haunted by Lucilius Perandus",
      "Haunted by Quintio Perandus",
      "100% increased chance of Elemental Modifiers",
      "+100 to Minion Modifier Tier Rating",
      "Reroll Implicit Modifier Values 3 times, keeping the best outcome",
      "Speed Modifiers are 100% scarcer",
      "+100 to Life Modifier Tier Rating",
      "+50 to Caster Modifier Tier Rating",
      "Creates a Ritual Helmet, Gloves or Boots",
      "Armour Items do not have a Dexterity Requirement",
      "Haunted by Drusia Perandus",
      "Mana Modifiers are 100% scarcer",
      "Creates a Helmet, Gloves or Boots with Ward",
      "Haunted by Lucilius Perandus",
      "+50 to Attack Modifier Tier Rating",
      "Haunted by Kostian Perandus",
      "+50 to Minion Modifier Tier Rating",
      "Mana Modifiers are 300% scarcer",
      "Haunted by Quintio Perandus",
      "Haunted by Kostian Perandus",
      "+100 to Defence Modifier Tier Rating",
      "Elemental Modifiers are 150% scarcer",
      "500% increased chance of Gem Modifiers",
      "+50 to Cold Modifier Tier Rating",
      "Life Modifiers are 100% scarcer",
      "Elemental Modifiers are 50% scarcer",
      "+100 to Fire Modifier Tier Rating",
      "Armour Items have a Dexterity Requirement",
      "Attack Modifiers are 50% scarcer",
      "500% increased chance of Critical Modifiers",
      "Haunted by Drusia Perandus",
      "Haunted by Lucilius Perandus",
      "+1 to minimum number of Linked Sockets, up to a maximum of 6",
      "25% chance to Fracture an Explicit Modifier",
      "Haunted by Claudia Veruso",
      "40% increased Effect of Beast Corpses adjacent to this Corpse",
      "40% increased Effect of Undead Corpses adjacent to this Corpse",
      "40% increased Effect of Humanoid Corpses adjacent to this Corpse",
      "+100 to Speed Modifier Tier Rating",
      "25% increased Effect of Corpses in this Grave Row",
      "Physical Modifiers are 300% scarcer",
      "Speed Modifiers are 300% scarcer",
      "+50 to Defence Modifier Tier Rating",
      "Chaos Modifiers are 300% scarcer",
      "40% increased Effect of Construct Corpses adjacent to this Corpse",
      "Attack Modifiers are 150% scarcer",
      "Armour Items have a Strength Requirement",
      "Haunted by Marius Nevalius",
      "+1 to minimum number of Linked Sockets, up to a maximum of 6",
      "Life Modifiers are 300% scarcer",
      "Reroll Modifier Values of each Explicit Modifier 6 times, keeping the best outcome",
      "Haunted by Tyche Grattus",
      "Attribute Modifiers are 300% scarcer",
      "40% increased Effect of Demon Corpses adjacent to this Corpse",
      "Defence Modifiers are 100% scarcer",
      "Haunted by Drusia Perandus",
      "+100 to Resistance Modifier Tier Rating",
      "Creates a Cerulean Ring, Requires at least 1 other Corpse of the same Monster Name",
      "Creates a Vermillion Ring, Requires at least 1 other Corpse of the same Monster Name",
      "Creates a Bone Ring",
      "Randomises the numeric values of base Defences on an Armour 6 times, keeping the best outcome",
      "Creates an Iolite Ring, Requires at least 1 other Corpse of the same Monster Name",
      "Resistance Modifiers are 300% scarcer",
      "Haunted by Julianis Nevalius",
      "+50 to Mana Modifier Tier Rating",
      "+100 to Critical Modifier Tier Rating",
      "25% increased Effect of Corpses in this Grave Column",
      "Haunted by Hadrius Veruso",
      "Haunted by Iris Phrecia",
      "+100 to Cold Modifier Tier Rating",
      "Haunted by Titiana Veruso",
      "500% increased chance of Lightning Modifiers",
      "Creates a Steel Ring, Requires at least 1 other Corpse of the same Monster Name",
      "200% increased chance of Lightning Modifiers",
      "200% increased chance of Cold Modifiers",
      "+50 to Modifier Tier Rating",
      "+50 to Speed Modifier Tier Rating",
      "+50 to Attribute Modifier Tier Rating",
      "Armour can roll Haunted Modifiers, 50% increased chance of Haunted Modifiers",
      "+50 to Lightning Modifier Tier Rating",
      "+100 to Attribute Modifier Tier Rating",
      "Haunted by Quintio Perandus",
      "Chaos Modifiers are 100% scarcer",
      "Physical Modifiers are 100% scarcer",
      "Haunted by Legius Grattus",
      "Armour Items do not have a Strength Requirement",
      "200% increased chance of Attribute Modifiers",
      "+1 to minimum number of Linked Sockets, up to a maximum of 4",
      "500% increased chance of Minion Modifiers",
      "100% increased chance of Caster Modifiers",
      "+25 to Caster Modifier Tier Rating",
      "Elemental Modifiers are 150% scarcer",
      "500% increased chance of Gem Modifiers",
      "500% increased chance of Speed Modifiers",
      "Mana Modifiers are 100% scarcer",
      "+5% to Quality, up to 30%",
      "500% increased chance of Cold Modifiers",
      "200% increased chance of Critical Modifiers",
      "+100 to Minion Modifier Tier Rating",
      "+100 to Lightning Modifier Tier Rating",
      "Haunted by Fausta Veruso",
      "+100 to Fire Modifier Tier Rating",
      "Armour can roll Haunted Modifiers, 150% increased chance of Haunted Modifiers",
      "5% chance for Construct Corpses to not be consumed when Exorcising, up to 50%",
      "500% increased chance of Resistance Modifiers",
      "5% chance for Demon Corpses to not be consumed when Exorcising, up to 50%",
      "Fire Modifiers are 300% scarcer",
      "Cold Modifiers are 300% scarcer",
      "Haunted by Octavius Nevalius",
      "500% increased chance of Fire Modifiers",
      "Elemental Modifiers are 50% scarcer",
      "500% increased chance of Attribute Modifiers",
      "Haunted by Lilius Nevalius",
      "+50 to Elemental Modifier Tier Rating",
      "200% increased chance of Defence Modifiers",
      "300% increased chance of Suffix Modifiers",
      "Haunted by Albanus Phrecia",
      "+1 to minimum number of Linked Sockets, up to a maximum of 5",
      "Haunted by Fabricia Phrecia",
      "500% increased chance of Chaos Modifiers",
      "Reroll Base Type 5 times, keeping the Base Type with the highest Level Requirement",
      "Critical Modifiers are 300% scarcer",
      "+50 to Resistance Modifier Tier Rating",
      "Reroll Implicit Modifier Values 6 times, keeping the best outcome",
      "Haunted by Valerius Phrecia",
      "+1 to minimum number of Linked Sockets, up to a maximum of 6",
      "25% chance to create a Split Copy",
      "Item sells for much more to Vendors, Requires at least 2 other Corpses of the same Monster Name",
      "Creates a Ritual Helmet, Gloves or Boots",
      "Reforge socket links 200 times, keeping the greatest number of links",
      "Haunted by Asinia Grattus",
      "5% chance for Beast Corpses to not be consumed when Exorcising, up to 50%",
      "300% increased chance for Prefix Modifiers",
      "Haunted by Draven Grattus",
      "5% chance for Humanoid Corpses to not be consumed when Exorcising, up to 50%",
      "+100 to Physical Modifier Tier Rating",
      "Creates an Opal Ring, Requires at least 1 other Corpse of the same Monster Name",
      "Armour Items have a Intelligence Requirement",
      "200% increased chance of Mana Modifiers",
      "+100 to Physical Modifier Tier Rating",
      "Defence Modifiers are 300% scarcer",
      "Caster Modifiers are 150% scarcer",
      "+50 to Fire Modifier Tier Rating",
      "100% increased chance of Suffix Modifiers",
      "+25 to Elemental Modifier Tier Rating",
      "300% increased chance of Elemental Modifiers",
      "200% increased chance of Life Modifiers",
      "+50 to Physical Modifier Tier Rating",
      "+100 to Lightning Modifier Tier Rating",
      "Caster Modifiers are 50% scarcer",
      "200% increased chance of Minion Modifiers",
      "Reforge socket numbers 200 times, keeping the greatest number of sockets",
      "Lightning Modifiers are 100% scarcer",
      "Cold Modifiers are 100% scarcer",
      "Reroll Modifier Values of each Explicit Modifier 3 times, keeping the best outcome",
      "-1 Explicit Modifiers",
      "100% increased chance of Attack Modifiers",
      "Randomises the numeric values of base Defences on an Armour 3 times, keeping the best outcome",
      "Attribute Modifiers are 100% scarcer",
      "Reforge socket links 20 times, keeping the greatest number of links",
      "+50 to Life Modifier Tier Rating",
      "+100 to Chaos Modifier Tier Rating",
      "300% increased chance of Caster Modifiers",
      "Physical Modifiers are 100% scarcer",
      "Physical Modifiers are 300% scarcer",
      "200% increased chance of Gem Modifiers",
      "Life Modifiers are 100% scarcer",
      "Attack Modifiers are 50% scarcer",
      "25% increased Effect of Corpses in this Grave Row",
      "25% chance to Fracture an Explicit Modifier",
      "+50 to Modifier Tier Rating",
      "40% increased Effect of Beast Corpses adjacent to this Corpse",
      "Reroll Modifier Values of each Explicit Modifier 6 times, keeping the best outcome",
      "Attribute Modifiers are 300% scarcer",
      "Mana Modifiers are 300% scarcer",
      "+100 to Mana Modifier Tier Rating",
      "+100 to Gem Modifier Tier Rating",
      "+100 to Defence Modifier Tier Rating",
      "+100 to Resistance Modifier Tier Rating",
      "Life Modifiers are 300% scarcer",
      "+50 to Critical Modifier Tier Rating",
      "+50 to Speed Modifier Tier Rating",
      "Chaos Modifiers are 300% scarcer",
      "+100 to Cold Modifier Tier Rating",
      "40% increased Effect of Undead Corpses adjacent to this Corpse",
      "Attack Modifiers are 150% scarcer",
      "+1 to minimum number of Linked Sockets, up to a maximum of 4",
      "+25 to Attack Modifier Tier Rating",
      "40% increased Effect of Humanoid Corpses adjacent to this Corpse",
      "200% increased chance of Speed Modifiers",
      "+100 to Attribute Modifier Tier Rating",
      "500% increased chance of Critical Modifiers",
      "500% increased chance of Speed Modifiers",
      "500% increased chance of Critical Modifiers",
      "+1 to Item Level",
      "500% increased chance of Physical Modifiers",
      "Reroll Modifier Values of each Explicit Modifier 3 times, keeping the best outcome",
      "500% increased chance of Speed Modifiers",
      "Chaos Modifiers are 100% scarcer",
      "+50 to Caster Modifier Tier Rating",
      "500% increased chance of Mana Modifiers",
      "Corpses in adjacent Graves and this Corpse have their crafting outcomes randomised when this Corpse is buried",
      "+50 to Attack Modifier Tier Rating",
      "500% increased chance of Life Modifiers",
      "+1 Explicit Modifier",
      "+100 to Speed Modifier Tier Rating",
      "5% chance for Undead Corpses to not be consumed when Exorcising, up to 50%",
      "Item is Corrupted, 50% increased chance for Corruption Implicit Modifiers",
      "500% increased chance of Gem Modifiers",
      "25% chance to Fracture an Explicit Modifier",
      "Resistance Modifiers are 100% scarcer",
      "300% increased chance of Attack Modifiers",
      "Lightning Modifiers are 300% scarcer",
      "+100 to Critical Modifier Tier Rating",
      "500% increased chance of Defence Modifiers",
      "+50 to Elemental Modifier Tier Rating",
      "500% increased chance of Gem Modifiers",
      "100% increased chance for Prefix Modifiers",
      "Fire Modifiers are 100% scarcer",
      "200% increased chance of Physical Modifiers",
      "+50 to Minion Modifier Tier Rating",
      "200% increased chance of Chaos Modifiers",
      "Armour Items do not have a Intelligence Requirement",
      "+100 to Chaos Modifier Tier Rating",
      "+100 to Life Modifier Tier Rating",
      "+50 to Gem Modifier Tier Rating",
      "Armour Items have a Dexterity Requirement",
      "200% increased chance of Gem Modifiers",
      "Elemental Modifiers are 150% scarcer",
      "+50 to Attack Modifier Tier Rating",
      "Creates a Bone Ring",
      "+100 to Fire Modifier Tier Rating",
      "+25 to Elemental Modifier Tier Rating",
      "Randomises the numeric values of base Defences on an Armour 6 times, keeping the best outcome",
      "Critical Modifiers are 100% scarcer",
      "Lightning Modifiers are 100% scarcer",
      "Randomises the numeric values of base Defences on an Armour 3 times, keeping the best outcome",
      "Reroll Modifier Values of each Explicit Modifier 6 times, keeping the best outcome",
      "+100 to Lightning Modifier Tier Rating",
      "25% increased Effect of Corpses in this Grave Column",
      "200% increased chance of Gem Modifiers",
      "200% increased chance of Minion Modifiers",
      "Reroll Modifier Values of each Explicit Modifier 3 times, keeping the best outcome",
      "+50 to Elemental Modifier Tier Rating",
      "100% increased chance for Prefix Modifiers",
      "+100 to Critical Modifier Tier Rating",
      "Haunted by Albanus Phrecia",
      "+25 to Caster Modifier Tier Rating",
      "+1 Explicit Modifier",
      "Caster Modifiers are 50% scarcer",
      "+50 to Attribute Modifier Tier Rating",
      "200% increased chance of Critical Modifiers",
      "Reforge socket numbers 30 times, keeping the greatest number of sockets",
      "500% increased chance of Life Modifiers",
      "200% increased chance of Attribute Modifiers",
      "200% increased chance of Chaos Modifiers",
      "300% increased chance of Elemental Modifiers",
      "300% increased chance of Suffix Modifiers",
      "500% increased chance of Resistance Modifiers",
      "Speed Modifiers are 100% scarcer",
      "500% increased chance of Physical Modifiers",
      "200% increased chance of Defence Modifiers",
      "100% increased chance of Suffix Modifiers",
      "Critical Modifiers are 300% scarcer",
      "200% increased chance of Mana Modifiers",
      "Lightning Modifiers are 300% scarcer",
      "200% increased chance of Resistance Modifiers",
      "300% increased chance of Attack Modifiers",
      "500% increased chance of Cold Modifiers",
      "Caster Modifiers are 150% scarcer",
      "Creates a Vermillion Ring, Requires at least 1 other Corpse of the same Monster Name",
      "Fire Modifiers are 300% scarcer",
      "500% increased chance of Fire Modifiers",
      "Speed Modifiers are 300% scarcer",
      "+100 to Mana Modifier Tier Rating",
      "+100 to Cold Modifier Tier Rating",
      "Reroll Implicit Modifier Values 6 times, keeping the best outcome",
      "+1 to Item Level",
      "Corpses in adjacent Graves and this Corpse have their crafting outcomes randomised when this Corpse is buried",
      "500% increased chance of Mana Modifiers",
      "-1 Explicit Modifiers",
      "Reforge socket links 200 times, keeping the greatest number of links",
      "+1 to minimum number of Linked Sockets, up to a maximum of 5",
      "5% chance for Beast Corpses to not be consumed when Exorcising, up to 50%",
      "500% increased chance of Lightning Modifiers",
      "Reforge socket numbers 200 times, keeping the greatest number of sockets",
      "+50 to Modifier Tier Rating",
      "500% increased chance of Defence Modifiers",
      "500% increased chance of Chaos Modifiers",
      "Haunted by Fabricia Phrecia",
      "Cold Modifiers are 300% scarcer",
      "Haunted by Lilius Nevalius",
      "500% increased chance of Attribute Modifiers",
      "Haunted by Octavius Nevalius",
      "Armour can roll Haunted Modifiers, 150% increased chance of Haunted Modifiers",
      "Haunted by Hadrius Veruso",
      "Haunted by Claudia Veruso",
      "500% increased chance of Minion Modifiers",
      "Haunted by Fausta Veruso",
      "Haunted by Tyche Grattus",
      "Haunted by Legius Grattus",
      "+5% to Quality, up to 30%",
      "100% increased chance of Attack Modifiers",
      "100% increased chance of Elemental Modifiers",
      "Haunted by Marius Nevalius",
      "Haunted by Valerius Phrecia",
      "Haunted by Iris Phrecia",
      "Haunted by Draven Grattus",
      "Reroll Base Type 5 times, keeping the Base Type with the highest Level Requirement",
      "Defence Modifiers are 300% scarcer",
      "Armour Items do not have a Dexterity Requirement",
      "Reforge socket links 20 times, keeping the greatest number of links",
      "Reroll Implicit Modifier Values 3 times, keeping the best outcome",
      "40% increased Effect of Demon Corpses adjacent to this Corpse",
      "+50 to Fire Modifier Tier Rating",
      "+50 to Chaos Modifier Tier Rating",
      "+50 to Life Modifier Tier Rating",
      "+50 to Mana Modifier Tier Rating",
      "200% increased chance of Cold Modifiers",
      "100% increased chance of Caster Modifiers",
      "Armour can roll Haunted Modifiers, 50% increased chance of Haunted Modifiers",
      "Chaos Modifiers are 300% scarcer",
      "Armour Items have a Intelligence Requirement",
      "+100 to Fire Modifier Tier Rating",
      "+100 to Minion Modifier Tier Rating",
      "5% chance for Humanoid Corpses to not be consumed when Exorcising, up to 50%",
      "5% chance for Demon Corpses to not be consumed when Exorcising, up to 50%",
      "200% increased chance of Physical Modifiers",
      "200% increased chance of Fire Modifiers",
      "200% increased chance of Speed Modifiers",
      "Armour Items have a Strength Requirement",
      "300% increased chance of Caster Modifiers",
      "25% chance to create a Split Copy",
      "+100 to Chaos Modifier Tier Rating",
      "5% chance for Undead Corpses to not be consumed when Exorcising, up to 50%",
      "+50 to Physical Modifier Tier Rating",
      "+50 to Resistance Modifier Tier Rating",
      "5% chance for Construct Corpses to not be consumed when Exorcising, up to 50%",
      "200% increased chance of Life Modifiers",
      "40% increased Effect of Construct Corpses adjacent to this Corpse",
      "200% increased chance of Lightning Modifiers",
      "Defence Modifiers are 100% scarcer",
      "Armour Items do not have a Strength Requirement",
      "Armour Items do not have a Intelligence Requirement",
      "+50 to Critical Modifier Tier Rating",
      "+100 to Life Modifier Tier Rating",
      "25% increased Effect of Corpses in this Grave Row",
      "+50 to Cold Modifier Tier Rating",
      "Fire Modifiers are 100% scarcer",
      "Attack Modifiers are 150% scarcer",
      "+100 to Cold Modifier Tier Rating",
      "+50 to Modifier Tier Rating",
      "Resistance Modifiers are 100% scarcer",
      "Haunted by Julianis Nevalius",
      "Attribute Modifiers are 300% scarcer",
      "+100 to Defence Modifier Tier Rating",
      "Mana Modifiers are 300% scarcer",
      "Physical Modifiers are 300% scarcer",
      "Chaos Modifiers are 300% scarcer",
      "Elemental Modifiers are 150% scarcer",
      "Creates a Steel Ring, Requires at least 1 other Corpse of the same Monster Name",
      "Attribute Modifiers are 300% scarcer",
      "Item sells for much more to Vendors, Requires at least 2 other Corpses of the same Monster Name",
      "+50 to Lightning Modifier Tier Rating",
      "+50 to Defence Modifier Tier Rating",
      "Creates an Opal Ring, Requires at least 1 other Corpse of the same Monster Name",
      "Cold Modifiers are 100% scarcer",
      "Elemental Modifiers are 50% scarcer",
      "+100 to Attribute Modifier Tier Rating",
      "+100 to Resistance Modifier Tier Rating",
      "Creates an Iolite Ring, Requires at least 1 other Corpse of the same Monster Name",
      "300% increased chance for Prefix Modifiers",
      "Physical Modifiers are 300% scarcer",
      "Mana Modifiers are 300% scarcer",
      "+100 to Physical Modifier Tier Rating",
      "+100 to Gem Modifier Tier Rating",
      "+50 to Caster Modifier Tier Rating",
      "+100 to Gem Modifier Tier Rating",
      "25% increased Effect of Corpses in this Grave Row",
      "200% increased chance of Resistance Modifiers",
      "+100 to Speed Modifier Tier Rating",
      "Randomises the numeric values of base Defences on an Armour 3 times, keeping the best outcome",
      "Reveals a Random Craft when Buried Rarer Crafts are more common",
      "+100 to Resistance Modifier Tier Rating",
      "Attack Modifiers are 50% scarcer",
      "Item is Corrupted, 50% increased chance for Corruption Implicit Modifiers",
      "Haunted by Titiana Veruso",
      "25% chance to create a Split Copy",
      "Haunted by Asinia Grattus",
      "Creates a Vermillion Ring, Requires at least 1 other Corpse of the same Monster Name",
      "40% increased Effect of Undead Corpses adjacent to this Corpse",
      "40% increased Effect of Beast Corpses adjacent to this Corpse",
      "200% increased chance of Minion Modifiers",
      "Attack Modifiers are 150% scarcer",
      "Chaos Modifiers are 100% scarcer",
      "Mana Modifiers are 100% scarcer",
      "+50 to Speed Modifier Tier Rating",
      "+50 to Minion Modifier Tier Rating",
      "+100 to Physical Modifier Tier Rating",
      "Creates a Cerulean Ring, Requires at least 1 other Corpse of the same Monster Name",
      "25% chance to create a Split Copy",
      "+50 to Gem Modifier Tier Rating",
      "+100 to Life Modifier Tier Rating",
      "+100 to Lightning Modifier Tier Rating",
      "+100 to Chaos Modifier Tier Rating",
      "+100 to Attribute Modifier Tier Rating",
      "Attribute Modifiers are 100% scarcer",
      "Armour Items have a Dexterity Requirement",
      "+50 to Fire Modifier Tier Rating",
      "+50 to Lightning Modifier Tier Rating",
      "500% increased chance of Minion Modifiers",
      "500% increased chance of Chaos Modifiers",
      "25% increased Effect of Corpses in this Grave Column",
      "Life Modifiers are 300% scarcer",
      "100% increased chance for Prefix Modifiers",
      "+1 to minimum number of Linked Sockets, up to a maximum of 4",
      "Reveals a Random Craft when Buried Rarer Crafts are more common",
      "+50 to Resistance Modifier Tier Rating",
      "+25 to Attack Modifier Tier Rating",
      "+100 to Mana Modifier Tier Rating",
      "200% increased chance of Critical Modifiers",
      "+25 to Elemental Modifier Tier Rating",
      "300% increased chance for Prefix Modifiers",
      "Physical Modifiers are 100% scarcer",
      "Speed Modifiers are 100% scarcer",
      "Life Modifiers are 300% scarcer",
      "Critical Modifiers are 300% scarcer",
      "Life Modifiers are 100% scarcer",
      "Creates an Iolite Ring, Requires at least 1 other Corpse of the same Monster Name",
      "Critical Modifiers are 100% scarcer",
      "Creates a Steel Ring, Requires at least 1 other Corpse of the same Monster Name",
      "25% increased Effect of Corpses in this Grave Column",
      "+50 to Caster Modifier Tier Rating",
      "+100 to Defence Modifier Tier Rating",
      "+50 to Gem Modifier Tier Rating",
      "40% increased Effect of Undead Corpses adjacent to this Corpse",
      "Creates a Cerulean Ring, Requires at least 1 other Corpse of the same Monster Name",
      "Creates an Opal Ring, Requires at least 1 other Corpse of the same Monster Name",
      "200% increased chance of Minion Modifiers",
      "500% increased chance of Minion Modifiers",
      "Randomises the numeric values of base Defences on an Armour 6 times, keeping the best outcome",
      "+50 to Minion Modifier Tier Rating",
      "Reveals a Random Craft when Buried Rarer Crafts are more common",
      "+100 to Minion Modifier Tier Rating",
      "500% increased chance of Lightning Modifiers",
      "40% increased Effect of Humanoid Corpses adjacent to this Corpse",
      "500% increased chance of Resistance Modifiers",
      "Caster Modifiers are 50% scarcer",
      "Defence Modifiers are 100% scarcer",
      "Fire Modifiers are 300% scarcer",
      "Armour Items have a Strength Requirement",
      "+50 to Physical Modifier Tier Rating",
      "+25 to Caster Modifier Tier Rating",
      "+1 to Item Level",
      "Reforge socket numbers 30 times, keeping the greatest number of sockets",
      "Reroll Base Type 5 times, keeping the Base Type with the highest Level Requirement",
      "+50 to Cold Modifier Tier Rating",
      "+50 to Defence Modifier Tier Rating",
      "100% increased chance of Caster Modifiers",
      "Haunted by Marius Nevalius",
      "Reforge socket numbers 200 times, keeping the greatest number of sockets",
      "Haunted by Octavius Nevalius",
      "500% increased chance of Fire Modifiers",
      "Haunted by Lilius Nevalius",
      "Haunted by Titiana Veruso",
      "Haunted by Claudia Veruso",
      "Haunted by Draven Grattus",
      "300% increased chance of Caster Modifiers",
      "Haunted by Draven Grattus",
      "Haunted by Fabricia Phrecia",
      "Haunted by Lilius Nevalius",
      "Lightning Modifiers are 300% scarcer",
      "5% chance for Humanoid Corpses to not be consumed when Exorcising, up to 50%",
      "Haunted by Fausta Veruso",
      "Haunted by Tyche Grattus",
      "Lightning Modifiers are 100% scarcer",
      "Armour can roll Haunted Modifiers, 50% increased chance of Haunted Modifiers",
      "Haunted by Marius Nevalius",
      "Armour Items have a Intelligence Requirement",
      "200% increased chance of Cold Modifiers",
      "200% increased chance of Life Modifiers",
      "Cold Modifiers are 100% scarcer",
      "Cold Modifiers are 300% scarcer",
      "Reroll Implicit Modifier Values 3 times, keeping the best outcome",
      "40% increased Effect of Demon Corpses adjacent to this Corpse",
      "40% increased Effect of Beast Corpses adjacent to this Corpse",
      "Reroll Base Type 5 times, keeping the Base Type with the highest Level Requirement",
      "200% increased chance of Fire Modifiers",
      "Armour Items do not have a Strength Requirement",
      "Armour Items do not have a Dexterity Requirement",
      "Haunted by Julianis Nevalius",
      "100% increased chance of Suffix Modifiers",
      "200% increased chance of Defence Modifiers",
      "+50 to Life Modifier Tier Rating",
      "Haunted by Albanus Phrecia",
      "Defence Modifiers are 300% scarcer",
      "200% increased chance of Resistance Modifiers",
      "200% increased chance of Physical Modifiers",
      "200% increased chance of Lightning Modifiers",
      "Haunted by Asinia Grattus",
      "Haunted by Iris Phrecia",
      "Haunted by Valerius Phrecia",
      "+50 to Chaos Modifier Tier Rating",
      "Haunted by Hadrius Veruso",
      "Item sells for much more to Vendors, Requires at least 2 other Corpses of the same Monster Name",
      "Armour Items do not have a Intelligence Requirement",
      "200% increased chance of Chaos Modifiers",
      "Haunted by Titiana Veruso",
      "Haunted by Iris Phrecia",
      "+50 to Attribute Modifier Tier Rating",
      "Haunted by Legius Grattus",
      "Reroll Implicit Modifier Values 6 times, keeping the best outcome",
      "500% increased chance of Attribute Modifiers",
      "+50 to Critical Modifier Tier Rating",
      "Reforge socket links 20 times, keeping the greatest number of links",
      "Haunted by Octavius Nevalius",
      "200% increased chance of Speed Modifiers",
      "Haunted by Valerius Phrecia",
      "+50 to Speed Modifier Tier Rating",
      "500% increased chance of Chaos Modifiers",
      "Haunted by Fabricia Phrecia",
      "Attribute Modifiers are 100% scarcer",
      "40% increased Effect of Construct Corpses adjacent to this Corpse",
      "Caster Modifiers are 150% scarcer",
      "100% increased chance of Attack Modifiers",
      "Haunted by Hadrius Veruso",
      "Haunted by Claudia Veruso",
      "200% increased chance of Attribute Modifiers",
      "Haunted by Tyche Grattus",
      "Critical Modifiers are 300% scarcer",
      "Haunted by Fausta Veruso",
      "Haunted by Asinia Grattus",
      "40% increased Effect of Humanoid Corpses adjacent to this Corpse",
      "Speed Modifiers are 300% scarcer",
      "Creates a Bone Ring",
      "Reforge socket links 200 times, keeping the greatest number of links",
      "Fire Modifiers are 100% scarcer",
      "Elemental Modifiers are 50% scarcer",
      "+50 to Mana Modifier Tier Rating",
      "Haunted by Legius Grattus",
      "Haunted by Albanus Phrecia",
      "100% increased chance for Prefix Modifiers",
      "Reroll Implicit Modifier Values 3 times, keeping the best outcome",
      "300% increased chance for Prefix Modifiers",
      "300% increased chance of Suffix Modifiers",
      "500% increased chance of Cold Modifiers",
      "Reforge socket links 200 times, keeping the greatest number of links",
      "Reveals a Random Craft when Buried Rarer Crafts are more common",
      "100% increased chance of Elemental Modifiers",
      "+5% to Quality, up to 30%",
      "500% increased chance of Resistance Modifiers",
      "500% increased chance of Fire Modifiers",
      "500% increased chance of Defence Modifiers",
      "+1 to Item Level",
      "500% increased chance of Mana Modifiers",
      "Speed Modifiers are 100% scarcer",
      "Armour Items have a Strength Requirement",
      "Armour Items have a Intelligence Requirement",
      "Armour can roll Haunted Modifiers, 150% increased chance of Haunted Modifiers",
      "Corpses in adjacent Graves and this Corpse have their crafting outcomes randomised when this Corpse is buried",
      "Corpses in adjacent Graves and this Corpse have their crafting outcomes randomised when this Corpse is buried",
      "+50 to Fire Modifier Tier Rating",
      "Armour can roll Haunted Modifiers, 50% increased chance of Haunted Modifiers",
      "Armour Items do not have a Dexterity Requirement",
      "200% increased chance of Defence Modifiers",
      "+1 Explicit Modifier",
      "300% increased chance of Attack Modifiers",
      "+1 Explicit Modifier",
      "500% increased chance of Cold Modifiers",
      "500% increased chance of Lightning Modifiers",
      "500% increased chance of Attribute Modifiers",
      "500% increased chance of Life Modifiers",
      "-1 Explicit Modifiers",
      "40% increased Effect of Demon Corpses adjacent to this Corpse",
      "40% increased Effect of Construct Corpses adjacent to this Corpse",
      "5% chance for Demon Corpses to not be consumed when Exorcising, up to 50%",
      "+1 to minimum number of Linked Sockets, up to a maximum of 5",
      "+25 to Elemental Modifier Tier Rating",
      "5% chance for Construct Corpses to not be consumed when Exorcising, up to 50%",
      "200% increased chance of Physical Modifiers",
      "200% increased chance of Cold Modifiers",
      "200% increased chance of Lightning Modifiers",
      "200% increased chance of Chaos Modifiers",
      "100% increased chance of Caster Modifiers",
      "Fire Modifiers are 100% scarcer",
      "+50 to Cold Modifier Tier Rating",
      "+50 to Lightning Modifier Tier Rating",
      "+25 to Caster Modifier Tier Rating",
      "Armour Items do not have a Intelligence Requirement",
      "200% increased chance of Mana Modifiers",
      "Item is Corrupted, 50% increased chance for Corruption Implicit Modifiers",
      "500% increased chance of Life Modifiers",
      "Reforge socket links 20 times, keeping the greatest number of links",
      "+1 to minimum number of Linked Sockets, up to a maximum of 4",
      "+1 to minimum number of Linked Sockets, up to a maximum of 5",
      "500% increased chance of Physical Modifiers",
      "+50 to Defence Modifier Tier Rating",
      "+50 to Life Modifier Tier Rating",
      "Armour Items have a Dexterity Requirement",
      "+5% to Quality, up to 30%",
      "200% increased chance of Life Modifiers",
      "300% increased chance of Caster Modifiers",
      "100% increased chance of Elemental Modifiers",
      "200% increased chance of Attribute Modifiers",
      "Cold Modifiers are 100% scarcer",
      "Lightning Modifiers are 100% scarcer",
      "Chaos Modifiers are 100% scarcer",
      "Life Modifiers are 100% scarcer",
      "Defence Modifiers are 300% scarcer",
      "Defence Modifiers are 100% scarcer",
      "Armour Items do not have a Strength Requirement",
      "Physical Modifiers are 100% scarcer",
      "Reforge socket numbers 30 times, keeping the greatest number of sockets",
      "5% chance for Demon Corpses to not be consumed when Exorcising, up to 50%",
      "500% increased chance of Mana Modifiers",
      "100% increased chance of Attack Modifiers",
      "300% increased chance of Attack Modifiers",
      "5% chance for Beast Corpses to not be consumed when Exorcising, up to 50%",
      "300% increased chance of Elemental Modifiers",
      "Creates a Bone Ring",
      "+50 to Chaos Modifier Tier Rating",
      "200% increased chance of Fire Modifiers",
      "500% increased chance of Defence Modifiers",
      "+50 to Attribute Modifier Tier Rating",
      "Item is Corrupted, 50% increased chance for Corruption Implicit Modifiers",
      "5% chance for Beast Corpses to not be consumed when Exorcising, up to 50%",
      "200% increased chance of Resistance Modifiers",
      "Critical Modifiers are 100% scarcer",
      "500% increased chance of Physical Modifiers",
      "Caster Modifiers are 50% scarcer",
      "+50 to Physical Modifier Tier Rating",
      "5% chance for Humanoid Corpses to not be consumed when Exorcising, up to 50%",
      "+25 to Attack Modifier Tier Rating",
      "200% increased chance of Mana Modifiers",
      "300% increased chance of Elemental Modifiers",
      "Mana Modifiers are 100% scarcer",
      "Attack Modifiers are 50% scarcer",
      "+50 to Mana Modifier Tier Rating",
      "5% chance for Undead Corpses to not be consumed when Exorcising, up to 50%",
      "5% chance for Undead Corpses to not be consumed when Exorcising, up to 50%",
      "Caster Modifiers are 150% scarcer",
      "-1 Explicit Modifiers",
      "Armour can roll Haunted Modifiers, 150% increased chance of Haunted Modifiers",
      "Haunted by Julianis Nevalius",
      "Lightning Modifiers are 300% scarcer",
      "Fire Modifiers are 300% scarcer",
      "Speed Modifiers are 300% scarcer",
      "Item sells for much more to Vendors, Requires at least 2 other Corpses of the same Monster Name",
      "Cold Modifiers are 300% scarcer",
      "Reforge socket numbers 200 times, keeping the greatest number of sockets",
      "5% chance for Construct Corpses to not be consumed when Exorcising, up to 50%",
      "Reroll Implicit Modifier Values 6 times, keeping the best outcome",
      "100% increased chance of Suffix Modifiers",
      "+50 to Resistance Modifier Tier Rating",
      "300% increased chance of Suffix Modifiers",
    ],
    name: "Coffinss ",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvTmVjcm9wb2xpcy9OZWNyb3BvbGlzQ29mZmluRmlsbGVkIiwidyI6MiwiaCI6MSwic2NhbGUiOjF9XQ/5b4214a420/NecropolisCoffinFilled.png",
  },
  currencyFilter: {
    items: [
      "Mirror of Kalandra",
      "Hinekora's Lock",
      "Mirror Shard",
      "Blessing of Chayula",
      "Fracturing Orb",
      "Hunter's Exalted Orb",
      "Veiled Orb",
      "Reality Fragment",
      "Divine Orb",
      "Orb of Dominance",
      "Awakener's Orb",
      "Crusader's Exalted Orb",
      "Redeemer's Exalted Orb",
      "Warlord's Exalted Orb",
      "Blessing of Xoph",
      "Blessing of Tul",
      "Blessing of Uul-Netol",
      "Devouring Fragment",
      "Blessing of Esh",
      "Tainted Divine Teardrop",
      "Sacred Crystallised Lifeforce",
      "Blazing Fragment",
      "Tempering Orb",
      "Tainted Orb of Fusing",
      "Eldritch Chaos Orb",
      "Tailoring Orb",
      "Decaying Fragment",
      "Synthesising Fragment",
      "Eldritch Orb of Annulment",
      "Orb of Conflict",
      "Awakening Fragment",
      "Cosmic Fragment",
      "Sacred Orb",
      "Fracturing Shard",
      "Prismatic Catalyst",
      "Exceptional Eldritch Ember",
      "Tainted Mythic Orb",
      "Exceptional Eldritch Ichor",
      "Tainted Exalted Orb",
      "Exalted Orb",
      "Eldritch Exalted Orb",
      "Oil Extractor",
      "Fertile Catalyst",
      "Tainted Chromatic Orb",
      "Tainted Chaos Orb",
      "Grand Eldritch Ichor",
      "Otherworldly Scouting Report",
      "Comprehensive Scouting Report",
      "Influenced Scouting Report",
      "Tainted Catalyst",
      "Orb of Annulment",
      "Ritual Vessel",
      "Tainted Jeweller's Orb",
      "Singular Scouting Report",
      "Ancient Orb",
      "Grand Eldritch Ember",
      "Veiled Scarab",
      "Greater Eldritch Ichor",
      "Intrinsic Catalyst",
      "Tempering Catalyst",
      "Unstable Catalyst",
      "Delirious Scouting Report",
      "Blighted Scouting Report",
      "Turbulent Catalyst",
      "Greater Eldritch Ember",
      "Lesser Eldritch Ichor",
      "Vaal Scouting Report",
      "Harbinger's Orb",
      "Stacked Deck",
      "Explorer's Scouting Report",
      "Tainted Armourer's Scrap",
      "Orb of Unmaking",
      "Gemcutter's Prism",
      "Lesser Eldritch Ember",
      "Annulment Shard",
      "Exalted Shard",
      "Imbued Catalyst",
      "Abrasive Catalyst",
      "Noxious Catalyst",
      "Accelerating Catalyst",
      "Operative's Scouting Report",
      "Instilling Orb",
      "Orb of Regret",
      "Blessed Orb",
      "Glassblower's Bauble",
      "Regal Orb",
      "Enkindling Orb",
      "Vaal Orb",
      "Cartographer's Chisel",
      "Orb of Scouring",
      "Tainted Blacksmith's Whetstone",
      "Orb of Alchemy",
      "Orb of Horizons",
      "Chromatic Orb",
      "Orb of Alteration",
      "Orb of Fusing",
      "Orb of Binding",
      "Jeweller's Orb",
      "Engineer's Orb",
      "Blacksmith's Whetstone",
      "Orb of Chance",
      "Orb of Augmentation",
      "Portal Scroll",
      "Armourer's Scrap",
      "Orb of Transmutation",
      "Wild Crystallised Lifeforce",
      "Vivid Crystallised Lifeforce",
      "Primal Crystallised Lifeforce",
      "Scroll of Wisdom",
    ],
    name: "Currencies",
    icon: "https://web.poecdn.com/image/Art/2DItems/Currency/CurrencyRerollRare.png?scale=1&w=1&h=1",
  },
  fragmentFilter: {
    items: [
      "Al-Hezmin's Crest",
      "Alva's Memory",
      "Baran's Crest",
      "Chayula's Breachstone",
      "Chayula's Charged Breachstone",
      "Chayula's Enriched Breachstone",
      "Chayula's Flawless Breachstone",
      "Chayula's Pure Breachstone",
      "Crescent Splinter",
      "Dedication to the Goddess",
      "Divine Vessel",
      "Drox's Crest",
      "Einhar's Memory",
      "Esh's Breachstone",
      "Esh's Charged Breachstone",
      "Esh's Enriched Breachstone",
      "Esh's Flawless Breachstone",
      "Esh's Pure Breachstone",
      "Fragment of Constriction",
      "Fragment of Emptiness",
      "Fragment of Enslavement",
      "Fragment of Eradication",
      "Fragment of Knowledge",
      "Fragment of Purification",
      "Fragment of Shape",
      "Fragment of Terror",
      "Fragment of the Chimera",
      "Fragment of the Hydra",
      "Fragment of the Minotaur",
      "Fragment of the Phoenix",
      "Gift to the Goddess",
      "Ignominious Fate",
      "Kirac's Memory",
      "Mortal Grief",
      "Mortal Hope",
      "Mortal Ignorance",
      "Mortal Rage",
      "Niko's Memory",
      "Offering to the Goddess",
      "Sacrifice at Dawn",
      "Sacrifice at Dusk",
      "Sacrifice at Midnight",
      "Sacrifice at Noon",
      "Simulacrum Splinter",
      "Simulacrum",
      "Splinter of Chayula",
      "Splinter of Esh",
      "Splinter of Tul",
      "Splinter of Uul-Netol",
      "Splinter of Xoph",
      "The Maven's Writ",
      "Timeless Eternal Emblem",
      "Timeless Eternal Empire Splinter",
      "Timeless Karui Emblem",
      "Timeless Karui Splinter",
      "Timeless Maraketh Emblem",
      "Timeless Maraketh Splinter",
      "Timeless Templar Emblem",
      "Timeless Templar Splinter",
      "Timeless Vaal Emblem",
      "Timeless Vaal Splinter",
      "Tribute to the Goddess",
      "Tul's Breachstone",
      "Tul's Charged Breachstone",
      "Tul's Enriched Breachstone",
      "Tul's Flawless Breachstone",
      "Tul's Pure Breachstone",
      "Unrelenting Timeless Eternal Emblem",
      "Unrelenting Timeless Karui Emblem",
      "Unrelenting Timeless Maraketh Emblem",
      "Unrelenting Timeless Templar Emblem",
      "Unrelenting Timeless Vaal Emblem",
      "Uul-Netol's Breachstone",
      "Uul-Netol's Charged Breachstone",
      "Uul-Netol's Enriched Breachstone",
      "Uul-Netol's Flawless Breachstone",
      "Uul-Netol's Pure Breachstone",
      "Veritania's Crest",
      "Victorious Fate",
      "Will of Chaos",
      "Xoph's Breachstone",
      "Xoph's Charged Breachstone",
      "Xoph's Enriched Breachstone",
      "Xoph's Flawless Breachstone",
      "Xoph's Pure Breachstone",
    ],
    name: "Fragments",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvQnJlYWNoL0JyZWFjaEZyYWdtZW50c0NoYW9zIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/04b5c032f4/BreachFragmentsChaos.png",
  },
  runeFilter: {
    items: [
      "Power Rune",
      "Time Rune",
      "Mountain Rune",
      "Journey Rune",
      "War Rune",
      "River Rune",
      "Bounty Rune",
      "Life Rune",
      "Bound Rune",
      "Sun Rune",
    ],
    name: "Kalguuran Runes",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2V0dGxlcnMvVmlsbGFnZVJ1bmUxMCIsInNjYWxlIjoxfV0/7a75e42c42/VillageRune10.png",
  },
  oilFilter: {
    items: [
      "Amber Oil",
      "Azure Oil",
      "Black Oil",
      "Clear Oil",
      "Crimson Oil",
      "Golden Oil",
      "Indigo Oil",
      "Opalescent Oil",
      "Reflective Oil",
      "Sepia Oil",
      "Silver Oil",
      "Tainted Oil",
      "Teal Oil",
      "Verdant Oil",
      "Violet Oil",
    ],
    name: "Oills",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvT2lscy9Hb2xkZW5PaWwiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/69094a06e9/GoldenOil.png",
  },
  scarabFilter: {
    items: [
      "Bestiary Scarab of the Shadowed Crow",
      "Horned Scarab of Preservation",
      "Horned Scarab of Glittering",
      "Ambush Scarab of Containment",
      "Ultimatum Scarab of Catalysing",
      "Horned Scarab of Pandemonium",
      "Horned Scarab of Awakening",
      "Essence Scarab of Calcification",
      "Domination Scarab of Terrors",
      "Divination Scarab of Pilfering",
      "Harvest Scarab of Cornucopia",
      "Ambush Scarab of Discernment",
      "Blight Scarab of Blooming",
      "Cartography Scarab of Singularity",
      "Horned Scarab of Bloodlines",
      "Horned Scarab of Tradition",
      "Ultimatum Scarab of Dueling",
      "Incursion Scarab of Timelines",
      "Cartography Scarab of Corruption",
      "Harbinger Scarab of Regency",
      "Essence Scarab of Ascent",
      "Ritual Scarab of Abundance",
      "Harvest Scarab of Doubling",
      "Sulphite Scarab of Greed",
      "Ambush Scarab",
      "Breach Scarab of Snares",
      "Harbinger Scarab of Warhoards",
      "Cartography Scarab of the Multitude",
      "Essence Scarab of Adaptation",
      "Blight Scarab of Invigoration",
      "Divination Scarab of The Cloister",
      "Legion Scarab of Eternal Conflict",
      "Legion Scarab of Officers",
      "Abyss Scarab of Profound Depth",
      "Titanic Scarab of Treasures",
      "Legion Scarab",
      "Harbinger Scarab",
      "Bestiary Scarab of Duplicating",
      "Expedition Scarab of Runefinding",
      "Horned Scarab of Nemeses",
      "Essence Scarab",
      "Bestiary Scarab of the Herd",
      "Delirium Scarab of Paranoia",
      "Ultimatum Scarab of Bribing",
      "Influencing Scarab of Hordes",
      "Scarab of Radiant Storms",
      "Cartography Scarab of Escalation",
      "Ambush Scarab of Hidden Compartments",
      "Breach Scarab of Resonant Cascade",
      "Scarab of Monstrous Lineage",
      "Ritual Scarab of Selectiveness",
      "Scarab of Adversaries",
      "Domination Scarab",
      "Abyss Scarab of Edifice",
      "Ultimatum Scarab of Inscription",
      "Expedition Scarab of Archaeology",
      "Blight Scarab of the Blightheart",
      "Influencing Scarab of Conversion",
      "Ambush Scarab of Potency",
      "Anarchy Scarab",
      "Betrayal Scarab of Intelligence",
      "Sulphite Scarab",
      "Delirium Scarab of Neuroses",
      "Harvest Scarab",
      "Breach Scarab",
      "Expedition Scarab of Verisium Powder",
      "Beyond Scarab",
      "Legion Scarab of Command",
      "Blight Scarab",
      "Incursion Scarab of Invasion",
      "Breach Scarab of Splintering",
      "Abyss Scarab",
      "Delirium Scarab",
      "Delirium Scarab of Mania",
      "Anarchy Scarab of Gigantification",
      "Abyss Scarab of Multitudes",
      "Bestiary Scarab",
      "Breach Scarab of Lordship",
      "Expedition Scarab",
      "Beyond Scarab of the Invasion",
      "Betrayal Scarab",
      "Influencing Scarab of the Elder",
      "Scarab of Hunted Traitors",
      "Beyond Scarab of Haemophilia",
      "Anarchy Scarab of Partnership",
      "Delirium Scarab of Delusions",
      "Betrayal Scarab of Reinforcements",
      "Incursion Scarab of Champions",
      "Beyond Scarab of Resurgence",
      "Scarab of Stability",
      "Scarab of Wisps",
      "Torment Scarab of Possession",
      "Scarab of Divinity",
      "Ritual Scarab of Wisps",
      "Cartography Scarab of Risk",
      "Harbinger Scarab of Obelisks",
      "Domination Scarab of Apparitions",
      "Titanic Scarab",
      "Divination Scarab of Plenty",
      "Domination Scarab of Evolution",
      "Titanic Scarab of Legend",
      "Scarab of Bisection",
      "Incursion Scarab",
      "Influencing Scarab of the Shaper",
      "Sulphite Scarab of Fumes",
      "Torment Scarab",
      "Essence Scarab of Stability",
      "Torment Scarab of Peculiarity",
      "Ultimatum Scarab",
    ],
    name: "Scarabs",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYkhhcmJpbmdlcnMiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/81caefbf3f/Tier4ScarabHarbingers.png",
  },
  highScarabFilter: {
    items: [
      "Gilded Divination Scarab",
      "Gilded Reliquary Scarab",
      "Gilded Harbinger Scarab",
      "Gilded Ambush Scarab",
      "Gilded Breach Scarab",
      "Gilded Abyss Scarab",
      "Gilded Expedition Scarab",
      "Gilded Legion Scarab",
      "Gilded Bestiary Scarab",
      "Gilded Blight Scarab",
      "Gilded Cartography Scarab",
      "Gilded Sulphite Scarab",
      "Gilded Metamorph Scarab",
      "Gilded Torment Scarab",
      "Gilded Shaper Scarab",
      "Gilded Elder Scarab",
      "Winged Divination Scarab",
      "Winged Reliquary Scarab",
      "Winged Harbinger Scarab",
      "Winged Ambush Scarab",
      "Winged Breach Scarab",
      "Winged Abyss Scarab",
      "Winged Expedition Scarab",
      "Winged Legion Scarab",
      "Winged Bestiary Scarab",
      "Winged Blight Scarab",
      "Winged Cartography Scarab",
      "Winged Sulphite Scarab",
      "Winged Metamorph Scarab",
      "Winged Torment Scarab",
      "Winged Shaper Scarab",
      "Winged Elder Scarab",
    ],
    name: "High Scarabs",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiSGFyYmluZ2VycyIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/ce393cfd58/GreaterScarabHarbingers.png",
  },
  fossilFilter: {
    items: [
      "Aberrant Fossil",
      "Aetheric Fossil",
      "Bloodstained Fossil",
      "Bound Fossil",
      "Corroded Fossil",
      "Deft Fossil",
      "Dense Fossil",
      "Faceted Fossil",
      "Fractured Fossil",
      "Frigid Fossil",
      "Fundamental Fossil",
      "Gilded Fossil",
      "Glyphic Fossil",
      "Hollow Fossil",
      "Jagged Fossil",
      "Lucent Fossil",
      "Metallic Fossil",
      "Perfect Fossil",
      "Prismatic Fossil",
      "Pristine Fossil",
      "Sanctified Fossil",
      "Scorched Fossil",
      "Serrated Fossil",
      "Shuddering Fossil",
      "Tangled Fossil",
    ],
    name: "Fossils",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvRGVsdmUvRmFjZXRlZEZvc3NpbCIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/db0aba6238/FacetedFossil.png",
  },
  essenceFilter: {
    items: [
      "Essence of Horror",
      "Essence of Hysteria",
      "Essence of Delirium",
      "Essence of Insanity",
      "Deafening Essence of Contempt",
      "Deafening Essence of Scorn",
      "Deafening Essence of Spite",
      "Deafening Essence of Wrath",
      "Deafening Essence of Rage",
      "Deafening Essence of Woe",
      "Deafening Essence of Dread",
      "Deafening Essence of Envy",
      "Deafening Essence of Greed",
      "Deafening Essence of Zeal",
      "Deafening Essence of Hatred",
      "Deafening Essence of Loathing",
      "Deafening Essence of Misery",
      "Deafening Essence of Sorrow",
      "Deafening Essence of Doubt",
      "Deafening Essence of Fear",
      "Shrieking Essence of Contempt",
      "Shrieking Essence of Scorn",
      "Shrieking Essence of Wrath",
      "Shrieking Essence of Greed",
      "Shrieking Essence of Spite",
      "Shrieking Essence of Woe",
      "Deafening Essence of Anger",
      "Deafening Essence of Anguish",
      "Deafening Essence of Suffering",
      "Deafening Essence of Torment",
      "Shrieking Essence of Dread",
      "Shrieking Essence of Envy",
      "Shrieking Essence of Rage",
      "Shrieking Essence of Sorrow",
      "Shrieking Essence of Zeal",
      "Muttering Essence of Anger",
      "Muttering Essence of Contempt",
      "Muttering Essence of Fear",
      "Muttering Essence of Greed",
      "Muttering Essence of Hatred",
      "Muttering Essence of Sorrow",
      "Muttering Essence of Torment",
      "Muttering Essence of Woe",
      "Remnant of Corruption",
      "Screaming Essence of Anger",
      "Screaming Essence of Contempt",
      "Screaming Essence of Doubt",
      "Screaming Essence of Dread",
      "Screaming Essence of Envy",
      "Screaming Essence of Fear",
      "Screaming Essence of Greed",
      "Screaming Essence of Hatred",
      "Screaming Essence of Loathing",
      "Screaming Essence of Misery",
      "Screaming Essence of Rage",
      "Screaming Essence of Scorn",
      "Screaming Essence of Sorrow",
      "Screaming Essence of Spite",
      "Screaming Essence of Suffering",
      "Screaming Essence of Torment",
      "Screaming Essence of Woe",
      "Screaming Essence of Wrath",
      "Screaming Essence of Zeal",
      "Shrieking Essence of Anger",
      "Shrieking Essence of Anguish",
      "Shrieking Essence of Doubt",
      "Shrieking Essence of Fear",
      "Shrieking Essence of Hatred",
      "Shrieking Essence of Loathing",
      "Shrieking Essence of Misery",
      "Shrieking Essence of Suffering",
      "Shrieking Essence of Torment",
      "Wailing Essence of Anger",
      "Wailing Essence of Anguish",
      "Wailing Essence of Contempt",
      "Wailing Essence of Doubt",
      "Wailing Essence of Fear",
      "Wailing Essence of Greed",
      "Wailing Essence of Hatred",
      "Wailing Essence of Loathing",
      "Wailing Essence of Rage",
      "Wailing Essence of Sorrow",
      "Wailing Essence of Spite",
      "Wailing Essence of Suffering",
      "Wailing Essence of Torment",
      "Wailing Essence of Woe",
      "Wailing Essence of Wrath",
      "Wailing Essence of Zeal",
      "Weeping Essence of Anger",
      "Weeping Essence of Contempt",
      "Weeping Essence of Doubt",
      "Weeping Essence of Fear",
      "Weeping Essence of Greed",
      "Weeping Essence of Hatred",
      "Weeping Essence of Rage",
      "Weeping Essence of Sorrow",
      "Weeping Essence of Suffering",
      "Weeping Essence of Torment",
      "Weeping Essence of Woe",
      "Weeping Essence of Wrath",
      "Whispering Essence of Contempt",
      "Whispering Essence of Greed",
      "Whispering Essence of Hatred",
      "Whispering Essence of Woe",
      "Screaming Essence of Anguish",
    ],
    name: "Essences",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvRXNzZW5jZS9Ib3Jyb3IxIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/748d594bde/Horror1.png",
  },
  allflameFilter: {
    name: "AllflameEmber",
    items: [
      "Allflame Ember of Manifested Wealth",
      "Flaring Allflame Ember of Scientists",
      "Flaring Allflame Ember of Kalguurans",
      "Allflame Ember of Anarchy",
      "Flaring Allflame Ember of Breachlords",
      "Flaring Allflame Ember of the Vaal",
      "Flaring Allflame Ember of The First Ones",
      "Allflame Ember of Syndicate Escorts",
      "Flaring Allflame Ember of Abyss",
      "Flaring Allflame Ember of Wildwood Cultists",
      "Allflame Ember of Synthetic Fury",
      "Allflame Ember of The Hunter",
      "Allflame Ember of Uul-Netol",
      "Allflame Ember of Syndicate Guards",
      "Allflame Ember of Frogs",
      "Allflame Ember of Syndicate Assassins",
      "Allflame Ember of Chayula",
      "Allflame Ember of Syndicate Researchers",
      "Allflame Ember of The Crusader",
      "Allflame Ember of The Warlord",
      "Allflame Ember of Ramako",
      "Allflame Ember of The Elder",
      "Allflame Ember of The Redeemer",
      "Allflame Ember of The Shaper",
      "Allflame Ember of Meatsacks",
      "Allflame Ember of The Vaal Legion",
      "Allflame Ember of Beidat",
      "Allflame Ember of Trial Guards",
      "Allflame Ember of Artillery Gemlings",
      "Allflame Ember of The Black Scythe",
      "Allflame Ember of The Templar Legion",
      "Allflame Ember of Kitava",
      "Allflame Ember of Trial Cores",
      "Allflame Ember of The Karui Legion",
      "Allflame Ember of Valako",
      "Allflame Ember of Arohongui",
      "Allflame Ember of Untainted Chimerals",
      "Allflame Ember of The Eternal Legion",
      "Allflame Ember of Tukohama",
      "Allflame Ember of Untainted Beasts",
      "Allflame Ember of Tasalio",
      "Allflame Ember of the False God",
      "Allflame Ember of Esh",
      "Allflame Ember of Untainted Devourers",
      "Allflame Ember of Abyss",
      "Allflame Ember of The Knights of the Sun",
      "Allflame Ember of Ghorr",
      "Allflame Ember of Tul",
      "Allflame Ember of Wildwood Cultists",
      "Allflame Ember of The Maraketh Legion",
      "Allflame Ember of Ngamahu",
      "Allflame Ember of Rongokurai",
      "Allflame Ember of Frostmage Gemlings",
      "Allflame Ember of Brawling Gemlings",
      "Allflame Ember of K'tash",
      "Allflame Ember of Untainted Rhoas",
      "Allflame Ember of Tawhoa",
      "Allflame Ember of Hinekora",
      "Allflame Ember of Untainted Maws",
      "Allflame Ember of Rats",
      "Allflame Ember of Berserking Gemlings",
      "Allflame Ember of Xoph",
      "Allflame Ember of The Order of the Chalice",
      "Allflame Ember of Wildwood Beasts",
      "Allflame Ember of Corpsemage Gemlings",
      "Allflame Ember of Sulphite",
      "Allflame Ember of The Broken Circle",
      "Allflame Ember of Untainted Apes",
      "Allflame Ember of Wildwood Treants",
      "Allflame Ember of Untainted Spitters",
    ],
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvTmVjcm9wb2xpcy9BbGxmbGFtZUVtYmVyc0Jhc2VDdXJyZW5jeSIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/fcf983d363/AllflameEmbersBaseCurrency.png",
  },
  highEssenceFilter: {
    name: "High Essence",
    items: [
      "Shrieking Essence of Contempt",
      "Shrieking Essence of Greed",
      "Shrieking Essence of Wrath",
      "Shrieking Essence of Zeal",
      "Shrieking Essence of Dread",
      "Shrieking Essence of Envy",
      "Shrieking Essence of Hatred",
      "Shrieking Essence of Loathing",
      "Shrieking Essence of Rage",
      "Shrieking Essence of Scorn",
      "Shrieking Essence of Sorrow",
      "Shrieking Essence of Spite",
      "Shrieking Essence of Anger",
      "Shrieking Essence of Fear",
      "Shrieking Essence of Woe",
      "Shrieking Essence of Anguish",
      "Shrieking Essence of Doubt",
      "Shrieking Essence of Misery",
      "Shrieking Essence of Suffering",
      "Shrieking Essence of Torment",
      "Deafening Essence of Contempt",
      "Deafening Essence of Greed",
      "Deafening Essence of Wrath",
      "Deafening Essence of Zeal",
      "Deafening Essence of Dread",
      "Deafening Essence of Envy",
      "Deafening Essence of Hatred",
      "Deafening Essence of Loathing",
      "Deafening Essence of Rage",
      "Deafening Essence of Scorn",
      "Deafening Essence of Sorrow",
      "Deafening Essence of Spite",
      "Deafening Essence of Anger",
      "Deafening Essence of Fear",
      "Deafening Essence of Woe",
      "Deafening Essence of Anguish",
      "Deafening Essence of Doubt",
      "Deafening Essence of Misery",
      "Deafening Essence of Suffering",
      "Deafening Essence of Torment",
      "Essence of Insanity",
      "Essence of Horror",
      "Essence of Delirium",
      "Essence of Hysteria",
    ],
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvQnJlYWNoL0JyZWFjaEZyYWdtZW50c0NoYW9zIiwic2NhbGUiOjF9XQ/4c99b7dd49/BreachFragmentsChaos.png",
  },

  incubatorFilter: {
    items: [
      "Geomancer's Incubator",
      "Time-Lost Incubator",
      "Thaumaturge's Incubator",
      "Diviner's Incubator",
      "Ornate Incubator",
      "Foreboding Incubator",
      "Infused Incubator",
      "Fragmented Incubator",
      "Singular Incubator",
      "Celestial Jeweller's Incubator",
      "Enchanted Incubator",
      "Cartographer's Incubator",
      "Skittering Incubator",
      "Fossilised Incubator",
      "Abyssal Incubator",
      "Mysterious Incubator",
      "Celestial Armoursmith's Incubator",
      "Otherworldly Incubator",
      "Obscured Incubator",
      "Decadent Incubator",
      "Celestial Blacksmith's Incubator",
      "Morphing Incubator",
      "Maddening Incubator",
      "Blighted Incubator",
      "Primal Incubator",
    ],
    name: "Incubators",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvSW5jdWJhdGlvbi9JbmN1YmF0aW9uQXJtb3VyIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/637c41a730/IncubationArmour.png",
  },
  artifactFilter: {
    items: ["Burial Medallion", "Exotic Coinage", "Astragali", "Scrap Metal"],
    name: "Artifact",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvRXhwZWRpdGlvbi9CYXJ0ZXJSZWZyZXNoQ3VycmVuY3kiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/bf3e6fbe8f/BarterRefreshCurrency.png",
  },

  blightRavagedMapFilter: {
    items: [
      "Blight-ravaged Ancient City Map",
      "Blight-ravaged Arachnid Nest Map",
      "Blight-ravaged Arachnid Nest Map",
      "Blight-ravaged Arcade Map",
      "Blight-ravaged Arsenal Map",
      "Blight-ravaged Arsenal Map",
      "Blight-ravaged Ashen Wood Map",
      "Blight-ravaged Ashen Wood Map",
      "Blight-ravaged Atoll Map",
      "Blight-ravaged Bazaar Map",
      "Blight-ravaged Bog Map",
      "Blight-ravaged Bone Crypt Map",
      "Blight-ravaged Bone Crypt Map",
      "Blight-ravaged Burial Chambers Map",
      "Blight-ravaged Burial Chambers Map",
      "Blight-ravaged Burial Chambers Map",
      "Blight-ravaged Caldera Map",
      "Blight-ravaged Caldera Map",
      "Blight-ravaged Canyon Map",
      "Blight-ravaged Canyon Map",
      "Blight-ravaged Canyon Map",
      "Blight-ravaged Castle Ruins Map",
      "Blight-ravaged Cemetery Map",
      "Blight-ravaged Cemetery Map",
      "Blight-ravaged Channel Map",
      "Blight-ravaged City Square Map",
      "Blight-ravaged City Square Map",
      "Blight-ravaged Cold River Map",
      "Blight-ravaged Cold River Map",
      "Blight-ravaged Colonnade Map",
      "Blight-ravaged Colonnade Map",
      "Blight-ravaged Coral Ruins Map",
      "Blight-ravaged Coral Ruins Map",
      "Blight-ravaged Courthouse Map",
      "Blight-ravaged Courthouse Map",
      "Blight-ravaged Courtyard Map",
      "Blight-ravaged Coves Map",
      "Blight-ravaged Coves Map",
      "Blight-ravaged Crimson Temple Map",
      "Blight-ravaged Crimson Township Map",
      "Blight-ravaged Crimson Township Map",
      "Blight-ravaged Cursed Crypt Map",
      "Blight-ravaged Cursed Crypt Map",
      "Blight-ravaged Dark Forest Map",
      "Blight-ravaged Defiled Cathedral Map",
      "Blight-ravaged Desert Map",
      "Blight-ravaged Dry Sea Map",
      "Blight-ravaged Dry Sea Map",
      "Blight-ravaged Dunes Map",
      "Blight-ravaged Dunes Map",
      "Blight-ravaged Dungeon Map",
      "Blight-ravaged Dungeon Map",
      "Blight-ravaged Estuary Map",
      "Blight-ravaged Estuary Map",
      "Blight-ravaged Forbidden Woods Map",
      "Blight-ravaged Foundry Map",
      "Blight-ravaged Foundry Map",
      "Blight-ravaged Gardens Map",
      "Blight-ravaged Ghetto Map",
      "Blight-ravaged Grave Trough Map",
      "Blight-ravaged Graveyard Map",
      "Blight-ravaged Iceberg Map",
      "Blight-ravaged Infested Valley Map",
      "Blight-ravaged Infested Valley Map",
      "Blight-ravaged Ivory Temple Map",
      "Blight-ravaged Laboratory Map",
      "Blight-ravaged Lair Map",
      "Blight-ravaged Leyline Map",
      "Blight-ravaged Leyline Map",
      "Blight-ravaged Lookout Map",
      "Blight-ravaged Mausoleum Map",
      "Blight-ravaged Mesa Map",
      "Blight-ravaged Mesa Map",
      "Blight-ravaged Mineral Pools Map",
      "Blight-ravaged Mineral Pools Map",
      "Blight-ravaged Moon Temple Map",
      "Blight-ravaged Museum Map",
      "Blight-ravaged Museum Map",
      "Blight-ravaged Necropolis Map",
      "Blight-ravaged Necropolis Map",
      "Blight-ravaged Orchard Map",
      "Blight-ravaged Overgrown Shrine Map",
      "Blight-ravaged Peninsula Map",
      "Blight-ravaged Plateau Map",
      "Blight-ravaged Plateau Map",
      "Blight-ravaged Plaza Map",
      "Blight-ravaged Port Map",
      "Blight-ravaged Port Map",
      "Blight-ravaged Precinct Map",
      "Blight-ravaged Precinct Map",
      "Blight-ravaged Primordial Blocks Map",
      "Blight-ravaged Primordial Blocks Map",
      "Blight-ravaged Primordial Pool Map",
      "Blight-ravaged Sepulchre Map",
      "Blight-ravaged Sepulchre Map",
      "Blight-ravaged Shipyard Map",
      "Blight-ravaged Shipyard Map",
      "Blight-ravaged Shore Map",
      "Blight-ravaged Shrine Map",
      "Blight-ravaged Silo Map",
      "Blight-ravaged Spider Lair Map",
      "Blight-ravaged Spider Lair Map",
      "Blight-ravaged Strand Map",
      "Blight-ravaged Sulphur Vents Map",
      "Blight-ravaged Thicket Map",
      "Blight-ravaged Thicket Map",
      "Blight-ravaged Toxic Sewer Map",
      "Blight-ravaged Underground River Map",
      "Blight-ravaged Underground Sea Map",
      "Blight-ravaged Underground Sea Map",
      "Blight-ravaged Underground Sea Map",
      "Blight-ravaged Villa Map",
      "Blight-ravaged Villa Map",
      "Blight-ravaged Volcano Map",
      "Blight-ravaged Wasteland Map",
      "Blight-ravaged Wasteland Map",
    ],
    name: "Blight Ravaged Maps",
    icon: "https://web.poecdn.com/gen/image/WzI4LDE0LHsiZiI6IjJESXRlbXMvTWFwcy9BdGxhczJNYXBzL05ldy9EZWZpbGVkQ2F0aGVkcmFsIiwidyI6MSwiaCI6MSwic2NhbGUiOjEsIm1uIjoxMywibXQiOjE2LCJtdWIiOnRydWV9XQ/88b6aa7f13/DefiledCathedral.png",
  },

  blightedMapFilter: {
    items: [
      "Blighted Acid Caverns Map",
      "Blighted Acid Caverns Map",
      "Blighted Acid Caverns Map",
      "Blighted Acid Caverns Map",
      "Blighted Alleyways Map",
      "Blighted Alleyways Map",
      "Blighted Alleyways Map",
      "Blighted Alleyways Map",
      "Blighted Alleyways Map",
      "Blighted Ancient City Map",
      "Blighted Arachnid Nest Map",
      "Blighted Arachnid Nest Map",
      "Blighted Arachnid Nest Map",
      "Blighted Arachnid Nest Map",
      "Blighted Arachnid Nest Map",
      "Blighted Arcade Map",
      "Blighted Arcade Map",
      "Blighted Arcade Map",
      "Blighted Arsenal Map",
      "Blighted Arsenal Map",
      "Blighted Arsenal Map",
      "Blighted Arsenal Map",
      "Blighted Ashen Wood Map",
      "Blighted Ashen Wood Map",
      "Blighted Atoll Map",
      "Blighted Atoll Map",
      "Blighted Atoll Map",
      "Blighted Atoll Map",
      "Blighted Atoll Map",
      "Blighted Bazaar Map",
      "Blighted Bazaar Map",
      "Blighted Bazaar Map",
      "Blighted Bog Map",
      "Blighted Bog Map",
      "Blighted Bog Map",
      "Blighted Bog Map",
      "Blighted Bone Crypt Map",
      "Blighted Bone Crypt Map",
      "Blighted Bone Crypt Map",
      "Blighted Bone Crypt Map",
      "Blighted Bone Crypt Map",
      "Blighted Burial Chambers Map",
      "Blighted Burial Chambers Map",
      "Blighted Burial Chambers Map",
      "Blighted Burial Chambers Map",
      "Blighted Cage Map",
      "Blighted Cage Map",
      "Blighted Cage Map",
      "Blighted Cage Map",
      "Blighted Cage Map",
      "Blighted Cage Map",
      "Blighted Caldera Map",
      "Blighted Caldera Map",
      "Blighted Caldera Map",
      "Blighted Caldera Map",
      "Blighted Caldera Map",
      "Blighted Canyon Map",
      "Blighted Canyon Map",
      "Blighted Canyon Map",
      "Blighted Castle Ruins Map",
      "Blighted Castle Ruins Map",
      "Blighted Castle Ruins Map",
      "Blighted Castle Ruins Map",
      "Blighted Castle Ruins Map",
      "Blighted Cemetery Map",
      "Blighted Cemetery Map",
      "Blighted Cemetery Map",
      "Blighted Channel Map",
      "Blighted Channel Map",
      "Blighted Channel Map",
      "Blighted City Square Map",
      "Blighted City Square Map",
      "Blighted City Square Map",
      "Blighted City Square Map",
      "Blighted Cold River Map",
      "Blighted Cold River Map",
      "Blighted Cold River Map",
      "Blighted Colonnade Map",
      "Blighted Colonnade Map",
      "Blighted Colonnade Map",
      "Blighted Coral Ruins Map",
      "Blighted Coral Ruins Map",
      "Blighted Coral Ruins Map",
      "Blighted Coral Ruins Map",
      "Blighted Coral Ruins Map",
      "Blighted Courthouse Map",
      "Blighted Courthouse Map",
      "Blighted Courthouse Map",
      "Blighted Courtyard Map",
      "Blighted Courtyard Map",
      "Blighted Courtyard Map",
      "Blighted Courtyard Map",
      "Blighted Coves Map",
      "Blighted Coves Map",
      "Blighted Coves Map",
      "Blighted Coves Map",
      "Blighted Coves Map",
      "Blighted Crimson Temple Map",
      "Blighted Crimson Temple Map",
      "Blighted Crimson Temple Map",
      "Blighted Crimson Temple Map",
      "Blighted Crimson Temple Map",
      "Blighted Crimson Township Map",
      "Blighted Crimson Township Map",
      "Blighted Crimson Township Map",
      "Blighted Crimson Township Map",
      "Blighted Cursed Crypt Map",
      "Blighted Cursed Crypt Map",
      "Blighted Cursed Crypt Map",
      "Blighted Dark Forest Map",
      "Blighted Dark Forest Map",
      "Blighted Dark Forest Map",
      "Blighted Defiled Cathedral Map",
      "Blighted Defiled Cathedral Map",
      "Blighted Defiled Cathedral Map",
      "Blighted Desert Map",
      "Blighted Desert Map",
      "Blighted Desert Map",
      "Blighted Dry Sea Map",
      "Blighted Dry Sea Map",
      "Blighted Dry Sea Map",
      "Blighted Dunes Map",
      "Blighted Dunes Map",
      "Blighted Dungeon Map",
      "Blighted Dungeon Map",
      "Blighted Dungeon Map",
      "Blighted Dungeon Map",
      "Blighted Dungeon Map",
      "Blighted Estuary Map",
      "Blighted Estuary Map",
      "Blighted Estuary Map",
      "Blighted Estuary Map",
      "Blighted Forbidden Woods Map",
      "Blighted Forbidden Woods Map",
      "Blighted Forbidden Woods Map",
      "Blighted Forking River Map",
      "Blighted Forking River Map",
      "Blighted Forking River Map",
      "Blighted Forking River Map",
      "Blighted Foundry Map",
      "Blighted Foundry Map",
      "Blighted Gardens Map",
      "Blighted Gardens Map",
      "Blighted Gardens Map",
      "Blighted Gardens Map",
      "Blighted Ghetto Map",
      "Blighted Grave Trough Map",
      "Blighted Grave Trough Map",
      "Blighted Grave Trough Map",
      "Blighted Graveyard Map",
      "Blighted Haunted Mansion Map",
      "Blighted Haunted Mansion Map",
      "Blighted Haunted Mansion Map",
      "Blighted Haunted Mansion Map",
      "Blighted Haunted Mansion Map",
      "Blighted Iceberg Map",
      "Blighted Iceberg Map",
      "Blighted Iceberg Map",
      "Blighted Iceberg Map",
      "Blighted Iceberg Map",
      "Blighted Infested Valley Map",
      "Blighted Infested Valley Map",
      "Blighted Infested Valley Map",
      "Blighted Infested Valley Map",
      "Blighted Infested Valley Map",
      "Blighted Ivory Temple Map",
      "Blighted Ivory Temple Map",
      "Blighted Ivory Temple Map",
      "Blighted Ivory Temple Map",
      "Blighted Laboratory Map",
      "Blighted Laboratory Map",
      "Blighted Laboratory Map",
      "Blighted Laboratory Map",
      "Blighted Lair Map",
      "Blighted Lair Map",
      "Blighted Lair Map",
      "Blighted Lava Lake Map",
      "Blighted Lava Lake Map",
      "Blighted Lava Lake Map",
      "Blighted Lava Lake Map",
      "Blighted Lava Lake Map",
      "Blighted Leyline Map",
      "Blighted Leyline Map",
      "Blighted Leyline Map",
      "Blighted Leyline Map",
      "Blighted Lookout Map",
      "Blighted Lookout Map",
      "Blighted Lookout Map",
      "Blighted Lookout Map",
      "Blighted Mausoleum Map",
      "Blighted Mausoleum Map",
      "Blighted Mausoleum Map",
      "Blighted Mausoleum Map",
      "Blighted Mausoleum Map",
      "Blighted Mesa Map",
      "Blighted Mesa Map",
      "Blighted Mesa Map",
      "Blighted Mineral Pools Map",
      "Blighted Mineral Pools Map",
      "Blighted Mineral Pools Map",
      "Blighted Mineral Pools Map",
      "Blighted Mineral Pools Map",
      "Blighted Mineral Pools Map",
      "Blighted Moon Temple Map",
      "Blighted Moon Temple Map",
      "Blighted Moon Temple Map",
      "Blighted Moon Temple Map",
      "Blighted Museum Map",
      "Blighted Museum Map",
      "Blighted Museum Map",
      "Blighted Museum Map",
      "Blighted Museum Map",
      "Blighted Necropolis Map",
      "Blighted Necropolis Map",
      "Blighted Necropolis Map",
      "Blighted Necropolis Map",
      "Blighted Orchard Map",
      "Blighted Orchard Map",
      "Blighted Overgrown Shrine Map",
      "Blighted Overgrown Shrine Map",
      "Blighted Overgrown Shrine Map",
      "Blighted Overgrown Shrine Map",
      "Blighted Peninsula Map",
      "Blighted Peninsula Map",
      "Blighted Peninsula Map",
      "Blighted Peninsula Map",
      "Blighted Peninsula Map",
      "Blighted Peninsula Map",
      "Blighted Plateau Map",
      "Blighted Plateau Map",
      "Blighted Plateau Map",
      "Blighted Plateau Map",
      "Blighted Plateau Map",
      "Blighted Plaza Map",
      "Blighted Plaza Map",
      "Blighted Plaza Map",
      "Blighted Port Map",
      "Blighted Port Map",
      "Blighted Port Map",
      "Blighted Port Map",
      "Blighted Port Map",
      "Blighted Precinct Map",
      "Blighted Precinct Map",
      "Blighted Precinct Map",
      "Blighted Precinct Map",
      "Blighted Precinct Map",
      "Blighted Primordial Blocks Map",
      "Blighted Primordial Blocks Map",
      "Blighted Primordial Blocks Map",
      "Blighted Primordial Blocks Map",
      "Blighted Primordial Pool Map",
      "Blighted Primordial Pool Map",
      "Blighted Primordial Pool Map",
      "Blighted Promenade Map",
      "Blighted Promenade Map",
      "Blighted Promenade Map",
      "Blighted Promenade Map",
      "Blighted Promenade Map",
      "Blighted Promenade Map",
      "Blighted Sepulchre Map",
      "Blighted Sepulchre Map",
      "Blighted Sepulchre Map",
      "Blighted Sepulchre Map",
      "Blighted Shipyard Map",
      "Blighted Shipyard Map",
      "Blighted Shipyard Map",
      "Blighted Shore Map",
      "Blighted Shore Map",
      "Blighted Shore Map",
      "Blighted Shore Map",
      "Blighted Shore Map",
      "Blighted Shrine Map",
      "Blighted Shrine Map",
      "Blighted Shrine Map",
      "Blighted Silo Map",
      "Blighted Silo Map",
      "Blighted Silo Map",
      "Blighted Silo Map",
      "Blighted Spider Forest Map",
      "Blighted Spider Forest Map",
      "Blighted Spider Forest Map",
      "Blighted Spider Forest Map",
      "Blighted Spider Lair Map",
      "Blighted Spider Lair Map",
      "Blighted Spider Lair Map",
      "Blighted Spider Lair Map",
      "Blighted Spider Lair Map",
      "Blighted Strand Map",
      "Blighted Strand Map",
      "Blighted Strand Map",
      "Blighted Strand Map",
      "Blighted Strand Map",
      "Blighted Sulphur Vents Map",
      "Blighted Sulphur Vents Map",
      "Blighted Sulphur Vents Map",
      "Blighted Sulphur Vents Map",
      "Blighted Sulphur Vents Map",
      "Blighted Temple Map",
      "Blighted Temple Map",
      "Blighted Temple Map",
      "Blighted Temple Map",
      "Blighted Thicket Map",
      "Blighted Thicket Map",
      "Blighted Thicket Map",
      "Blighted Thicket Map",
      "Blighted Thicket Map",
      "Blighted Toxic Sewer Map",
      "Blighted Underground River Map",
      "Blighted Underground River Map",
      "Blighted Underground River Map",
      "Blighted Underground Sea Map",
      "Blighted Underground Sea Map",
      "Blighted Underground Sea Map",
      "Blighted Villa Map",
      "Blighted Villa Map",
      "Blighted Villa Map",
      "Blighted Villa Map",
      "Blighted Volcano Map",
      "Blighted Volcano Map",
      "Blighted Volcano Map",
      "Blighted Volcano Map",
      "Blighted Wasteland Map",
      "Blighted Wasteland Map",
      "Blighted Wasteland Map",
      "Blighted Wasteland Map",
    ],
    name: "Blighted Maps",
    icon: "https://web.poecdn.com/gen/image/WzI4LDE0LHsiZiI6IjJESXRlbXMvTWFwcy9BdGxhczJNYXBzL05ldy9Gb3JraW5nUml2ZXIiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MSwibW4iOjExLCJtdCI6MTUsIm1iIjp0cnVlfV0/d14b4572d0/ForkingRiver.png",
  },
  deliriumOrbFilter: {
    items: [
      "Skittering Delirium Orb",
      "Cartographer's Delirium Orb",
      "Diviner's Delirium Orb",
      "Fossilised Delirium Orb",
      "Thaumaturge's Delirium Orb",
      "Fine Delirium Orb",
      "Amorphous Delirium Orb",
      "Obscured Delirium Orb",
      "Foreboding Delirium Orb",
      "Singular Delirium Orb",
      "Blighted Delirium Orb",
      "Timeless Delirium Orb",
      "Fragmented Delirium Orb",
      "Whispering Delirium Orb",
      "Imperial Delirium Orb",
      "Jeweller's Delirium Orb",
      "Abyssal Delirium Orb",
      "Armoursmith's Delirium Orb",
      "Blacksmith's Delirium Orb",
    ],
    name: "Delirium Orbs",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvRGVsaXJpdW0vRGVsaXJpdW1PcmJTY2FyYWJzIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/fa4c5160ca/DeliriumOrbScarabs.png",
  },
  invitationFilter: {
    items: [
      "Incandescent Invitation",
      "Screaming Invitation",
      "Maven's Invitation: The Elderslayers",
      "Maven's Invitation: The Formed",
      "Maven's Invitation: The Twisted",
      "Maven's Invitation: The Forgotten",
      "Maven's Invitation: The Hidden",
      "Polaric Invitation",
      "Maven's Invitation: The Feared",
      "Writhing Invitation",
      "Maven's Invitation: The Atlas",
    ],
    name: "Invitation",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvQXRsYXMvTnVsbFZvaWQyIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/3c5b06022e/NullVoid2.png",
  },
  beastFilter: {
    items: [
      "Bleached Crawler",
      "Craicic Chimeral",
      "Farric Tiger Alpha",
      "Fenumal Plagued Arachnid",
      "Farrul, First of the Plains",
      "Parasite",
      "Fenumus, First of the Night",
      "Saqawine Rhex",
      "Craicic Maw",
      "Craiceann, First of the Deep",
      "Merveil's Favoured",
      "Fenumal Hybrid Arachnid",
      "Saqawal, First of the Sky",
      "Saqawine Cobra",
      "Farric Wolf Alpha",
      "Farric Lynx Alpha",
      "Rotting Vulture",
      "Saqawine Vulture",
      "Adolescent Rhex",
      "Craicic Spider Crab",
      "Avalanche Rider",
      "Craicic Vassal",
      "Escaped Rhex",
      "Pitbull Demon",
      "Infested Rhoa",
      "Spitting Parasite",
      "Craicic Savage Crab",
      "Farric Frost Hellion Alpha",
      "Craicic Watcher",
      "Merveil's Chosen",
      "Stygian Ape",
      "Freezing Wolf",
      "Hairy Bonecruncher",
      "Predatory Scorpion",
      "Brine Vassal",
      "Spindle Spider",
      "Leaping Spider",
      "Fenumal Queen",
      "Fenumal Devourer",
      "Farric Pit Hound",
      "Saqawine Blood Viper",
      "Fenumal Scrabbler",
      "Barb Serpent",
      "Savage Crab",
      "Purge Hound",
      "Murk Runner",
      "Vicious Parasite",
      "Forest Beast",
      "Shaggy Monstrosity",
      "Sewage Crawler",
      "Maternal Rhex",
      "Blood Chieftain",
      "Chrome-touched Chimeral",
      "Corrupted Rhoa",
      "Farric Ape",
      "Black Scorpion",
      "Enraptured Beast",
      "Bone Cruncher",
      "Cursed Spawn",
      "Farric Goliath",
      "Fenumal Scorpion",
      "Vaulting Croaker",
      "Sand Skitterer",
      "Mountain Hellion Alpha",
      "Fetid Maw",
      "Echo of the Verdant",
      "Alpine Shaman",
      "Bone Rhoa",
      "Elder-Blessed Rhoa",
      "Elder-Blessed Hellion",
      "Elder-Blessed Goatman",
      "Crypt Ambusher",
      "Elder-Blessed Cobra",
      "Lurking Venom",
      "Enraptured Crab",
      "Scum Crawler",
      "Farric Magma Hound",
      "Farric Flame Hellion Alpha",
      "Flame Hellion",
      "Avian Retch",
      "Dune Hellion",
      "Carrion Queen",
      "Porcupine Goliath",
      "Mountain Lynx",
      "Alpine Devil",
      "Mountain Hellion",
      "Rhoa Mare",
      "Mindless Scavenger",
      "Chimeric Croaker",
      "Carrion Swarmer",
      "Plummeting Ursa",
      "Chrome-touched Goliath",
      "Carrion Burrower",
      "Host Chieftain",
      "Corrupted Beast",
      "Cave Beast",
      "Carnage Ape",
      "Fury Hound",
      "Goatman Shaman",
      "Gravel Eater",
      "Plated Parasite",
      "Colossus Crusher",
      "Dust Scrabbler",
      "Grazing Taurus",
      "Gluttonous Gull",
      "Barrow Ape",
      "Scavenging Vulture",
      "Spine Serpent",
      "Merveil's Blessed",
      "Night Adder",
      "Glade Mamba",
      "Scalding Arachnid",
      "Bearded Devil",
      "Merveil's Attendant",
      "Arakaali's Daughter",
      "Plumed Chimeral",
      "Obsidian Eater",
      "Talon Archer",
      "Bramble Cobra",
      "Tercel Rhoa",
      "Chrome-touched Croaker",
      "Chrome-infused Croaker",
      "Hybrid Arachnid",
      "Carnage Chieftain",
      "Infested Crab",
      "Dread Primate",
      "Blood Ape",
      "Singing Siren",
      "Snow Wolf",
      "Ink Spinner",
      "Infested Tunnelfiend",
      "Goatman",
      "Bearded Shaman",
      "Chrome-infused Chimeral",
      "Cave Skitterer",
      "Venomous Spawn",
      "Webbed Spider",
      "Virulent Spider",
      "Enraptured Arachnid",
      "Corrupted Arach",
      "Crustacean Sniper",
      "Infested Ape",
      "Merveil's Retainer",
      "Host Cobra",
      "Sulphuric Scorpion",
      "Soulless Watcher",
      "Rooster Fiend",
      "Bearded Skycaller",
      "Infested Skitterer",
      "Goatman Fire-raiser",
      "Acid Slitherer",
      "Host Adder",
      "Skeletal Beast",
      "Farric Taurus",
      "Fenumal Widow",
      "Sand Leaper",
      "Chrome-infused Goliath",
      "Granite Eater",
      "Plagued Arachnid",
      "Noxious Tarantula",
      "Rock Spitter",
      "Ruins Hellion",
      "Mutant Arach",
      "Craicic Sand Spitter",
      "Farric Chieftain",
      "Infested Maw",
      "Hill Devil",
      "Devourer",
      "Shackled Hellion",
      "Fighting Bull",
      "Saqawine Rhoa",
      "Saqawine Chimeral",
      "Enslaved Hellion",
      "Dirt Scrabbler",
      "Craicic Squid",
      "Farric Ursa",
      "Saqawine Retch",
      "Craicic Shield Crab",
      "Farric Gargantuan",
      "Farric Goatman",
    ],
    name: "Beasts",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvQmVzdGlhcnlPcmJGdWxsIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/3214b44360/BestiaryOrbFull.png",
  },
  resonatorFilter: {
    items: [
      "Prime Chaotic Resonator",
      "Prime Alchemical Resonator",
      "Powerful Chaotic Resonator",
      "Powerful Alchemical Resonator",
      "Potent Alchemical Resonator",
      "Potent Chaotic Resonator",
      "Primitive Chaotic Resonator",
      "Primitive Alchemical Resonator",
    ],
    name: "Resonators",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvRGVsdmUvUmVyb2xsMngyQyIsInciOjIsImgiOjIsInNjYWxlIjoxfV0/584267701b/Reroll2x2C.png",
  },
  divinationFilter: {
    items: [
      "A Chilling Wind",
      "A Dab of Ink",
      "A Dusty Memory",
      "A Familiar Call",
      "A Fate Worse Than Death",
      "A Modest Request",
      "A Mother's Parting Gift",
      "A Note in the Wind",
      "A Sea of Blue",
      "A Stone Perfected",
      "Abandoned Wealth",
      "Acclimatisation",
      "Akil's Prophecy",
      "Alivia's Grace",
      "Alluring Bounty",
      "Alone in the Darkness",
      "Altered Perception",
      "Ambitious Obsession",
      "Anarchy's Price",
      "Arrogance of the Vaal",
      "Assassin's Favour",
      "Assassin's Gift",
      "Astral Protection",
      "Atziri's Arsenal",
      "Audacity",
      "Auspicious Ambitions",
      "Azure Rage",
      "Azyran's Reward",
      "Baited Expectations",
      "Beauty Through Death",
      "Bijoux",
      "Blind Venture",
      "Boon of Justice",
      "Boon of the First Ones",
      "Boundless Realms",
      "Bowyer's Dream",
      "Broken Promises",
      "Broken Truce",
      "Brother's Gift",
      "Brother's Stash",
      "Brotherhood in Exile",
      "Brush, Paint and Palette",
      "Buried Treasure",
      "Burning Blood",
      "Call to the First Ones",
      "Cameria's Cut",
      "Cartographer's Delight",
      "Chaotic Disposition",
      "Chasing Risk",
      "Checkmate",
      "Choking Guilt",
      "Costly Curio",
      "Council of Cats",
      "Coveted Possession",
      "Cursed Words",
      "Dark Dreams",
      "Dark Temptation",
      "Darker Half",
      "Deadly Joy",
      "Death",
      "Deathly Designs",
      "Dementophobia",
      "Demigod's Wager",
      "Desecrated Virtue",
      "Desperate Crusade",
      "Destined to Crumble",
      "Dialla's Subjugation",
      "Disdain",
      "Divine Beauty",
      "Doedre's Madness",
      "Draped in Dreams",
      "Duality",
      "Dying Light",
      "Earth Drinker",
      "Echoes of Love",
      "Eldritch Perfection",
      "Emperor of Purity",
      "Emperor's Luck",
      "Endless Night",
      "Etched in Blood",
      "Eternal Bonds",
      "Ever-Changing",
      "Fateful Meeting",
      "Fire Of Unknown Origin",
      "Forbidden Power",
      "From Bone to Ashes",
      "Further Invention",
      "Gemcutter's Mercy",
      "Gemcutter's Promise",
      "Gift of Asenath",
      "Gift of the Gemling Queen",
      "Glimmer of Hope",
      "Grave Knowledge",
      "Guardian's Challenge",
      "Harmony of Souls",
      "Haunting Shadows",
      "Her Mask",
      "Heterochromia",
      "Home",
      "Hope",
      "House of Mirrors",
      "Hubris",
      "Humility",
      "Hunter's Resolve",
      "Hunter's Reward",
      "I See Brothers",
      "Immortal Resolve",
      "Imperfect Memories",
      "Imperial Legacy",
      "Jack in the Box",
      "Judging Voices",
      "Justified Ambition",
      "Keeper's Corruption",
      "Lachrymal Necrosis",
      "Lantador's Lost Love",
      "Last Hope",
      "Left to Fate",
      "Lethean Temptation",
      "Light and Truth",
      "Lingering Remnants",
      "Lost Worlds",
      "Love Through Ice",
      "Loyalty",
      "Lucky Connections",
      "Lucky Deck",
      "Luminous Trove",
      "Lysah's Respite",
      "Magnum Opus",
      "Man With Bear",
      "Matryoshka",
      "Mawr Blaidd",
      "Merciless Armament",
      "Might is Right",
      "Misery in Darkness",
      "Mitts",
      "Monochrome",
      "More is Never Enough",
      "No Traces",
      "Nook's Crown",
      "Parasitic Passengers",
      "Peaceful Moments",
      "Perfection",
      "Poisoned Faith",
      "Prejudice",
      "Pride Before the Fall",
      "Pride of the First Ones",
      "Prometheus' Armoury",
      "Prosperity",
      "Rain Tempter",
      "Rain of Chaos",
      "Rats",
      "Rebirth and Renewal",
      "Rebirth",
      "Reckless Ambition",
      "Remembrance",
      "Sambodhi's Vow",
      "Sambodhi's Wisdom",
      "Scholar of the Seas",
      "Seven Years Bad Luck",
      "Shard of Fate",
      "Silence and Frost",
      "Society's Remorse",
      "Something Dark",
      "Soul Quenched",
      "Struck by Lightning",
      "Succor of the Sinless",
      "The Academic",
      "The Admirer",
      "The Adventuring Spirit",
      "The Aesthete",
      "The Apothecary",
      "The Archmage's Right Hand",
      "The Arena Champion",
      "The Army of Blood",
      "The Artist",
      "The Aspirant",
      "The Astromancer",
      "The Avenger",
      "The Awakened",
      "The Battle Born",
      "The Bear Woman",
      "The Beast",
      "The Betrayal",
      "The Bitter Blossom",
      "The Blazing Fire",
      "The Body",
      "The Bones",
      "The Brawny Battle Mage",
      "The Breach",
      "The Brittle Emperor",
      "The Cache",
      "The Cacophony",
      "The Calling",
      "The Card Sharp",
      "The Carrion Crow",
      "The Cartographer",
      "The Cataclysm",
      "The Catalyst",
      "The Catch",
      "The Celestial Justicar",
      "The Celestial Stone",
      "The Chains that Bind",
      "The Cheater",
      "The Chosen",
      "The Coming Storm",
      "The Conduit",
      "The Craving",
      "The Cursed King",
      "The Damned",
      "The Dapper Prodigy",
      "The Dark Mage",
      "The Darkest Dream",
      "The Deal",
      "The Deceiver",
      "The Deep Ones",
      "The Demon",
      "The Demoness",
      "The Destination",
      "The Doctor",
      "The Doppelganger",
      "The Dragon",
      "The Dragon's Heart",
      "The Dreamer",
      "The Dreamland",
      "The Drunken Aristocrat",
      "The Dungeon Master",
      "The Easy Stroll",
      "The Eldritch Decay",
      "The Emptiness",
      "The Encroaching Darkness",
      "The Endless Darkness",
      "The Endurance",
      "The Enforcer",
      "The Enlightened",
      "The Enthusiasts",
      "The Escape",
      "The Eternal War",
      "The Ethereal",
      "The Explorer",
      "The Eye of Terror",
      "The Eye of the Dragon",
      "The Fathomless Depths",
      "The Feast",
      "The Fiend",
      "The Finishing Touch",
      "The Fishmonger",
      "The Fletcher",
      "The Flora's Gift",
      "The Fool",
      "The Forgotten Treasure",
      "The Formless Sea",
      "The Forsaken",
      "The Fortunate",
      "The Forward Gaze",
      "The Fox in the Brambles",
      "The Fox",
      "The Gambler",
      "The Garish Power",
      "The Gemcutter",
      "The Gentleman",
      "The Gladiator",
      "The Golden Era",
      "The Greatest Intentions",
      "The Gulf",
      "The Hale Heart",
      "The Harvester",
      "The Hermit",
      "The Heroic Shot",
      "The Hive of Knowledge",
      "The Hoarder",
      "The Hunger",
      "The Immortal",
      "The Incantation",
      "The Innocent",
      "The Inoculated",
      "The Insane Cat",
      "The Insatiable",
      "The Inventor",
      "The Jester",
      "The Jeweller's Boon",
      "The Journalist",
      "The Journey",
      "The King's Blade",
      "The King's Heart",
      "The Landing",
      "The Last One Standing",
      "The Last Supper",
      "The Leviathan",
      "The Lich",
      "The Life Thief",
      "The Lion",
      "The Long Con",
      "The Lord in Black",
      "The Lord of Celebration",
      "The Lover",
      "The Lunaris Priestess",
      "The Mad King",
      "The Magma Crab",
      "The Master Artisan",
      "The Master",
      "The Mercenary",
      "The Messenger",
      "The Metalsmith's Gift",
      "The Mind's Eyes",
      "The Mountain",
      "The Nurse",
      "The Oath",
      "The Obscured",
      "The Offering",
      "The Offspring",
      "The Old Man",
      "The One That Got Away",
      "The One With All",
      "The Opulent",
      "The Pack Leader",
      "The Pact",
      "The Patient",
      "The Penitent",
      "The Poet",
      "The Polymath",
      "The Porcupine",
      "The Price of Devotion",
      "The Price of Loyalty",
      "The Price of Prescience",
      "The Price of Protection",
      "The Primordial",
      "The Prince of Darkness",
      "The Professor",
      "The Progeny of Lunaris",
      "The Puzzle",
      "The Queen",
      "The Rabbit's Foot",
      "The Rabid Rhoa",
      "The Realm",
      "The Return of the Rat",
      "The Risk",
      "The Rite of Elements",
      "The Road to Power",
      "The Rusted Bard",
      "The Ruthless Ceinture",
      "The Sacrifice",
      "The Saint's Treasure",
      "The Samurai's Eye",
      "The Scarred Meadow",
      "The Scavenger",
      "The Scholar",
      "The Scout",
      "The Seeker",
      "The Sephirot",
      "The Shepherd's Sandals",
      "The Shieldbearer",
      "The Shortcut",
      "The Side Quest",
      "The Sigil",
      "The Siren",
      "The Skeleton",
      "The Soul",
      "The Spark and the Flame",
      "The Spoiled Prince",
      "The Standoff",
      "The Stormcaller",
      "The Strategist",
      "The Summoner",
      "The Sun",
      "The Surgeon",
      "The Surveyor",
      "The Survivalist",
      "The Sword King's Salute",
      "The Thaumaturgist",
      "The Throne",
      "The Tinkerer's Table",
      "The Tireless Extractor",
      "The Tower",
      "The Traitor",
      "The Transformation",
      "The Trial",
      "The Tumbleweed",
      "The Twilight Moon",
      "The Twins",
      "The Tyrant",
      "The Undaunted",
      "The Undisputed",
      "The Unexpected Prize",
      "The Union",
      "The Valkyrie",
      "The Vast",
      "The Visionary",
      "The Void",
      "The Warden",
      "The Warlord",
      "The Watcher",
      "The Web",
      "The Wedding Gift",
      "The White Knight",
      "The Whiteout",
      "The Wilted Rose",
      "The Wind",
      "The Witch",
      "The Wolf",
      "The Wolf's Legacy",
      "The Wolf's Shadow",
      "The Wolven King's Bite",
      "The Wolverine",
      "The World Eater",
      "The Wrath",
      "The Wretched",
      "Thirst for Knowledge",
      "Three Faces in the Dark",
      "Three Voices",
      "Thunderous Skies",
      "Time-Lost Relic",
      "Tranquillity",
      "Treasure Hunter",
      "Triskaidekaphobia",
      "Turn the Other Cheek",
      "Unchained",
      "Underground Forest",
      "Unrequited Love",
      "Vanity",
      "Vile Power",
      "Vinia's Token",
      "Void of the Elements",
      "Volatile Power",
      "Wealth and Power",
      "Who Asked",
      "Winter's Embrace",
    ],
    name: "Divination Cards",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvRGl2aW5hdGlvbi9JbnZlbnRvcnlJY29uIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/f34bf8cbb5/InventoryIcon.png",
  },

  tattooFilter: {
    items: [
      "Tattoo of the Ramako Makanga",
      "Honoured Tattoo of the Wise",
      "Honoured Tattoo of the Berserker",
      "Tattoo of the Arohongui Makanga",
      "Honoured Tattoo of the Storm",
      "Honoured Tattoo of the Barbarian",
      "Honoured Tattoo of the Hatungo",
      "Tattoo of the Ngamahu Makanga",
      "Honoured Tattoo of the Flock",
      "Honoured Tattoo of the Hunter",
      "Honoured Tattoo of the Warlord",
      "Tattoo of the Tasalio Makanga",
      "Honoured Tattoo of the Pa",
      "Honoured Tattoo of the Mountain",
      "Tattoo of the Ramako Sniper",
      "Tattoo of the Tawhoa Herbalist",
      "Tattoo of the Ngamahu Warmonger",
      "Tattoo of the Tawhoa Makanga",
      "Tattoo of the Hinekora Makanga",
      "Honoured Tattoo of the Oak",
      "Tattoo of the Kitava Makanga",
      "Honoured Tattoo of the Tuatara",
      "Tattoo of the Ramako Shaman",
      "Tattoo of the Valako Makanga",
      "Tattoo of the Tukohama Makanga",
      "Tattoo of the Tukohama Warcaller",
      "Honoured Tattoo of the Pillager",
      "Tattoo of the Ramako Fleetfoot",
      "Tattoo of the Valako Stormrider",
      "Honoured Tattoo of the Dove",
      "Tattoo of the Rongokurai Turtle",
      "Loyalty Tattoo of Rakiata",
      "Tattoo of the Rongokurai Makanga",
      "Tattoo of the Tasalio Scout",
      "Tattoo of the Tukohama Warmonger",
      "Loyalty Tattoo of Ahuana",
      "Loyalty Tattoo of Maata",
      "Loyalty Tattoo of Ikiaho",
      "Honoured Tattoo of the Flood",
      "Tattoo of the Rongokurai Warrior",
      "Tattoo of the Hinekora Shaman",
      "Tattoo of the Arohongui Moonwarden",
      "Tattoo of the Hinekora Storyteller",
      "Honoured Tattoo of the Turtle",
      "Tattoo of the Arohongui Shaman",
      "Tattoo of the Ngamahu Firewalker",
      "Tattoo of the Hinekora Warmonger",
      "Tattoo of the Kitava Shaman",
      "Loyalty Tattoo of Kiloava",
      "Tattoo of the Hinekora Warrior",
      "Tattoo of the Hinekora Deathwarden",
      "Tattoo of the Tukohama Warrior",
      "Tattoo of the Valako Scout",
      "Tattoo of the Ramako Archer",
      "Tattoo of the Arohongui Warrior",
      "Tattoo of the Valako Shieldbearer",
      "Honoured Tattoo of the Sky",
      "Tattoo of the Tawhoa Shaman",
      "Tattoo of the Ngamahu Shaman",
      "Tattoo of the Ngamahu Woodcarver",
      "Tattoo of the Tasalio Warrior",
      "Tattoo of the Kitava Warrior",
      "Tattoo of the Valako Shaman",
      "Loyalty Tattoo of Akoya",
      "Loyalty Tattoo of Kahuturoa",
      "Loyalty Tattoo of Utula",
      "Loyalty Tattoo of Kaom",
      "Tattoo of the Rongokurai Goliath",
      "Tattoo of the Tawhoa Naturalist",
      "Tattoo of the Valako Warrior",
      "Tattoo of the Tukohama Shaman",
      "Tattoo of the Tasalio Bladedancer",
      "Tattoo of the Tasalio Shaman",
      "Tattoo of the Arohongui Scout",
      "Tattoo of the Tawhoa Warrior",
      "Tattoo of the Tawhoa Scout",
      "Tattoo of the Tukohama Brawler",
      "Tattoo of the Tasalio Tideshifter",
      "Tattoo of the Rongokurai Brute",
      "Tattoo of the Ngamahu Warrior",
      "Tattoo of the Ramako Scout",
      "Tattoo of the Rongokurai Guard",
      "Tattoo of the Arohongui Warmonger",
      "Tattoo of the Kitava Blood Drinker",
      "Tattoo of the Kitava Heart Eater",
      "Tattoo of the Kitava Rebel",
      "Loyalty Tattoo of Tawhanuku",
    ],
    name: "Tattoo ",
    icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvQW5jZXN0b3JzL0NvbW1vbkRleFRhdHR0b29FcXVpcG1lbnQiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/b80711f45f/CommonDexTatttooEquipment.png",
  },
};
